import {Route, Routes} from 'react-router-dom';

import DefaultPage from '../defaultPage/DefaultPage';
import Users from '../users/Users';
import Groups from '../groups/Groups';
import CloudPCs from '../cloudPcs/CloudPCs';
import Dashboard from '../dashboard/Dashboard';

function MainPane() {
  return (
    <Routes>
      <Route path="/" element={<Dashboard />} exact />
      <Route path="/dashboard" element={<Dashboard />} exact />
      <Route path="/cloudpc" element={<CloudPCs />} exact />
      <Route path="/users" element={<Users />} exact />
      <Route path="/groups" element={<Groups />} exact />
      <Route path="*" element={<DefaultPage />} />
    </Routes>
  );
}

export default MainPane;
