import {useEffect, useMemo, useState} from 'react';
import {
  Box,
  IconButton,
  LinearProgress,
  Link,
  List,
  Paper,
  TextField,
  Tooltip,
} from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Typography from '@mui/material/Typography';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {useDispatch, useSelector} from 'react-redux';
import {enqueueSnackbar} from 'notistack';
import {
  activateLicense,
  deleteLicense,
  submitLicense,
} from '../../redux/actions/licenseActions';
import {Can} from '../common/Can';
import LicenseItem from './LicenseItem';

function License() {
  const dispatch = useDispatch();
  const {licenses, isLoading, error} = useSelector((state) => state.licenses);
  const {tenantStatus} = useSelector((state) => state.tenantStatus);

  const [registrationCode, setRegistrationCode] = useState('');
  const [isValid, setIsValid] = useState(true);

  const validateForm = (code) => {
    setIsValid(
      /^[A-Z0-9]{12}@[A-Z0-9]{4}-[A-Z0-9]{4}-[A-Z0-9]{4}-[A-Z0-9]{4}$/.test(
        code.trim()
      )
    );
  };

  useEffect(() => {
    if (error) {
      enqueueSnackbar(error, {variant: 'error'});
    }
  }, [error, enqueueSnackbar]);

  const handleChange = (event) => {
    const {value} = event.target;
    setRegistrationCode(value.toUpperCase());
    validateForm(value);
  };

  const isFormDisabled = useMemo(
    () => !tenantStatus?.consentStatus || isLoading,
    [tenantStatus, isLoading]
  );

  const handleRemoveLicense = (licenseKey) => {
    dispatch(deleteLicense(licenseKey));
  };

  const handleActivateLicense = (licenseKey) => {
    dispatch(activateLicense(licenseKey));
  };
  const handleAddLicense = () => {
    if (registrationCode && isValid) {
      dispatch(submitLicense(registrationCode.trim()));
      setRegistrationCode('');
    }
  };

  return (
    <Paper
      sx={{
        marginTop: '20px',
        padding: '20px',
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
        maxWidth: '800px',
      }}
    >
      <Typography variant="overline">
        Enter a valid HP Anyware registration code
        <Tooltip
          title={
            <Typography variant="caption">
              HP Anyware registration code should be retrieved from HP. Contact{' '}
              <Link
                href="https://www.hp.com/us-en/services/workforce-solutions/workforce-computing/digital-workspaces.html#modal=contact"
                sx={{color: 'inherit', textDecoration: 'underline'}}
              >
                HP Anyware Sales
              </Link>{' '}
              for license code if you don&apos;t have one.
            </Typography>
          }
        >
          <IconButton
            aria-label="More information"
            sx={{
              verticalAlign: 'initial',
              '& .MuiSvgIcon-root': {fontSize: '1rem'},
            }}
          >
            <InfoOutlinedIcon color="primary" />
          </IconButton>
        </Tooltip>
      </Typography>
      <Can I="create" a="license" passThrough>
        {(canCreateLicense) => (
          <Box display="flex" alignItems="flex-start">
            <TextField
              disabled={!canCreateLicense}
              error={!isValid && registrationCode}
              onChange={handleChange}
              helperText={
                isValid || !registrationCode
                  ? ' '
                  : 'Registration code is not valid.'
              }
              label="Enter Registration Code"
              placeholder="ABCDEFGHIJKL@0123-4567-89AB-CDEF"
              variant="outlined"
              size="small"
              value={registrationCode}
              InputProps={{
                sx: {
                  fontFamily: 'monospace',
                },
              }}
              sx={{
                width: '40ch',
                '& .MuiOutlinedInput-root': {
                  '&:hover fieldset': {
                    borderColor: 'primary.main',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: 'primary.main',
                  },
                },
              }}
              data-testid="license-input"
            />
            <IconButton
              disabled={
                !canCreateLicense ||
                !isValid ||
                !registrationCode ||
                isFormDisabled
              }
              onClick={handleAddLicense}
              color="primary"
              sx={{marginBlock: '0'}}
            >
              <AddCircleOutlineIcon />
            </IconButton>
          </Box>
        )}
      </Can>
      {licenses.length > 1 && (
        <Typography variant="subtitle2">
          Select a license to set it as active. Only one license can be active
          at a time. When you activate a license, that license will be set as
          the active license and all existing Anyware Agents will be updated
          with the new license automatically.
        </Typography>
      )}
      <Box
        display="flex"
        flexDirection="column"
        alignItems={isLoading ? 'center' : 'flex-start'}
        justifyContent={isLoading ? 'center' : 'flex-start'}
        sx={{height: `calc(58px * ${Math.max(licenses.length, 1)})`}}
      >
        {isLoading ? (
          <>
            <Typography marginBottom={2}>Updating Licenses...</Typography>
            <LinearProgress sx={{width: '100%'}} />
          </>
        ) : (
          <List>
            {licenses.map((license) => (
              <LicenseItem
                key={license._id}
                license={license}
                onActivate={handleActivateLicense}
                onDelete={handleRemoveLicense}
              />
            ))}
          </List>
        )}
      </Box>
    </Paper>
  );
}

export default License;
