import Button from '@mui/material/Button';
import {styled} from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';

const PREFIX = 'LoginButton';

const classes = {
  root: `${PREFIX}-root`,
  icon: `${PREFIX}-icon`,
  text: `${PREFIX}-text`,
  buttonLabel: `${PREFIX}-buttonLabel`,
};

const StyledButton = styled(Button)(() => ({
  [`&.${classes.root}`]: {
    textTransform: 'none',
    width: '100%',
    minHeight: '2.5em',
    maxWidth: '400px',
    margin: '0 auto',
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    backgroundColor: '#FFFFFF',
    padding: '6px 20px',
    justifyContent: 'center',
    fontSize: 14,
    borderRadius: '25px',
    border: '1px solid rgb(102,117,246)',
    '&:hover': {
      backgroundColor: '#fff',
    },
    '&:active': {
      backgroundColor: 'rgba(0, 0, 0, 0.1)',
    },
  },

  [`& .${classes.icon}`]: {
    height: '22px',
    marginRight: '10px',
  },

  [`& .${classes.text}`]: {
    color: 'rgb(102,117,246)',
    fontSize: '1rem',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 500,
    lineHeight: 1.5,
    letterSpacing: '0.00938em',
  },

  [`& .${classes.buttonLabel}`]: {
    alignItems: 'unset',
    justifyContent: 'unset',
  },
}));

function LoginButton({idp, iconSrc, onClick, text, link}) {
  return (
    <StyledButton
      id={`${idp}Login`}
      data-testid={`${idp}Login`}
      variant="outlined"
      color="inherit"
      onClick={onClick}
      className={classes.root}
      href={link}
      classes={{label: classes.buttonLabel}}
    >
      {iconSrc && <img alt={idp} src={iconSrc} className={classes.icon} />}
      <Typography className={classes.text}>{text}</Typography>
    </StyledButton>
  );
}

LoginButton.propTypes = {
  idp: PropTypes.string.isRequired,
  iconSrc: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  link: PropTypes.string,
};

LoginButton.defaultProps = {
  link: undefined,
};

export default LoginButton;
