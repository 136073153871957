import keyMirror from 'keymirror';
import {PARTNER_GROUP_STATUS_PENDING} from '../../utils/constants';
import {get, post} from '../../api/Api';

export const groupActions = keyMirror({
  FETCH_GROUPS_START: null,
  FETCH_GROUPS_SUCCESS: null,
  FETCH_GROUPS_FAILURE: null,
  FETCH_GROUP_MEMBER_START: null,
  FETCH_GROUP_MEMBER_SUCCESS: null,
  FETCH_GROUP_MEMBER_FAILURE: null,
  FETCH_GROUP_STATUS_START: null,
  FETCH_GROUP_STATUS_SUCCESS: null,
  FETCH_GROUP_STATUS_FAILURE: null,
  UPDATE_GROUP_STATUS_START: null,
  UPDATE_GROUP_STATUS_SUCCESS: null,
  UPDATE_GROUP_STATUS_FAILURE: null,
});

// Fetch Groups Actions

export const fetchGroupsStart = () => ({
  type: groupActions.FETCH_GROUPS_START,
});

export const fetchGroupsSuccess = (groups) => ({
  type: groupActions.FETCH_GROUPS_SUCCESS,
  payload: groups,
});

export const fetchGroupsFailure = (error) => ({
  type: groupActions.FETCH_GROUPS_FAILURE,
  payload: error,
});

// Fetch Group Member Actions
export const fetchGroupMemberStart = (groupId) => ({
  type: groupActions.FETCH_GROUP_MEMBER_START,
  payload: {groupId},
});

export const fetchGroupMemberSuccess = (groupId, members) => ({
  type: groupActions.FETCH_GROUP_MEMBER_SUCCESS,
  payload: {groupId, members},
});

export const fetchGroupMemberFailure = (groupId, error) => ({
  type: groupActions.FETCH_GROUP_MEMBER_FAILURE,
  payload: {groupId, error},
});

// Fetch Group Status Actions

export const fetchGroupStatusStart = (groupId) => ({
  type: groupActions.FETCH_GROUP_STATUS_START,
  payload: {groupId},
});

export const fetchGroupStatusSuccess = (groupId, provisionStatus) => ({
  type: groupActions.FETCH_GROUP_STATUS_SUCCESS,
  payload: {groupId, provisionStatus},
});

export const fetchGroupStatusFailure = (groupId, error) => ({
  type: groupActions.FETCH_GROUP_STATUS_FAILURE,
  payload: {groupId, error},
});

// Update Group Status Actions

export const updateGroupStatusStart = (groupId) => ({
  type: groupActions.UPDATE_GROUP_STATUS_START,
  payload: {groupId},
});

export const updateGroupStatusSuccess = (groupId, provisionStatus) => ({
  type: groupActions.UPDATE_GROUP_STATUS_SUCCESS,
  payload: {groupId, provisionStatus},
});

export const updateGroupStatusFailure = (groupId, error) => ({
  type: groupActions.UPDATE_GROUP_STATUS_FAILURE,
  payload: {groupId, error},
});

// Thunk Actions

export const fetchGroups =
  (page, pageSize, searchTerm, orderBy) => async (dispatch) => {
    dispatch(fetchGroupsStart());
    try {
      const response = await get({
        path: `groups?page=${page}&pageSize=${pageSize}&searchTerm=${searchTerm}&orderBy=${orderBy}`,
      });
      dispatch(fetchGroupsSuccess(response.data));
    } catch (error) {
      dispatch(fetchGroupsFailure(error.data.message));
    }
  };

export const fetchGroupMembers = (groupId) => async (dispatch) => {
  dispatch(fetchGroupMemberStart(groupId));
  try {
    const response = await get({path: `groups/${groupId}/members`});
    dispatch(fetchGroupMemberSuccess(groupId, response.data));
  } catch (error) {
    dispatch(fetchGroupMemberFailure(groupId, error.data.message));
  }
};

export const fetchGroupStatus = (groupId) => async (dispatch) => {
  dispatch(fetchGroupStatusStart(groupId));
  try {
    const response = await get({path: `groups/${groupId}`});
    dispatch(fetchGroupStatusSuccess(groupId, response.data.provisionStatus));
  } catch (error) {
    dispatch(fetchGroupStatusFailure(groupId, error.data.message));
  }
};

export const updateGroupStatus = (groupId, action) => async (dispatch) => {
  dispatch(updateGroupStatusStart(groupId));
  const urlMap = {
    activate: `groups/${groupId}/activate`,
    deactivate: `groups/${groupId}/deactivate`,
    refresh: `groups/${groupId}/refresh`,
  };
  try {
    const response = await post({
      path: urlMap[action],
      data: {},
    });
    if (response.code === 200) {
      dispatch(updateGroupStatusSuccess(groupId, PARTNER_GROUP_STATUS_PENDING));
    } else {
      throw new Error(response.error || 'Unknown error occurred');
    }
  } catch (error) {
    dispatch(updateGroupStatusFailure(groupId, error.data.message));
  }
};
