import {useContext, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import Backdrop from '@mui/material/Backdrop';
import Typography from '@mui/material/Typography';
import {fetchTenantApprovalStatus} from '../../redux/actions/approvedTenantActions';
import config from '../../config';
import {handleLogout} from '../../utils/utils';
import UnapprovedTenantDialog from './UnapprovedTenantDialog';
import UnlockDashboardDialog from './UnlockDashboardDialog';
import AdminUserDialog from './AdminUserDialog';
import {AbilityContext} from '../common/Can';

function LoadingBackdrop() {
  return (
    <Backdrop open>
      <Typography variant="h3">Loading...</Typography>
    </Backdrop>
  );
}

export default function Gatekeeper({children}) {
  const dispatch = useDispatch();
  const [isInitialized, setIsInitialized] = useState(false);

  const {isInitialized: tenantStatusInitialized, tenantStatus} = useSelector(
    (state) => state.tenantStatus
  );
  const {isInitialized: tenantApprovalInitialized, isApproved} = useSelector(
    (state) => state.approvedTenant
  );

  const allTrue = (arr) => !arr.includes(false);

  useEffect(() => {
    dispatch(fetchTenantApprovalStatus());
  }, [dispatch]);

  useEffect(() => {
    const intializeStates = [
      tenantApprovalInitialized,
      tenantStatusInitialized,
    ];
    setIsInitialized(allTrue(intializeStates));
  }, [tenantApprovalInitialized, tenantStatusInitialized]);

  const ability = useContext(AbilityContext);

  const requireTenantApproval = !isApproved;
  const requirePrivacyPolicy = !tenantStatus?.privacyPolicy?.accepted;
  const requireEula = !tenantStatus?.eula?.accepted;
  const requirePolicyAcceptance = requirePrivacyPolicy || requireEula;
  const requireAdminUser =
    tenantStatus?.consentStatus && ability.cannot('view', 'dashboard');

  if (!isInitialized) {
    return <LoadingBackdrop />;
  }

  if (requireTenantApproval) {
    return (
      <UnapprovedTenantDialog
        redirectUrl={config.UNAPPROVED_TENANT_REDIRECT_LINK}
        redirectSeconds={config.UNAPPROVED_TENANT_REDIRECT_SECONDS}
        handleLogout={handleLogout}
      />
    );
  }

  if (requirePolicyAcceptance) {
    return <UnlockDashboardDialog />;
  }

  if (requireAdminUser) {
    return <AdminUserDialog handleLogout={handleLogout} />;
  }

  return children;
}

Gatekeeper.propTypes = {
  children: PropTypes.node.isRequired,
};
