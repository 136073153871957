import PropTypes from 'prop-types';

import Button from '@mui/material/Button';
import MuiDialogActions from '@mui/material/DialogActions';
import MuiDialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';

import GatekeeperDialog, {classes} from './GatekeeperDialog';

export default function AdminUserDialog(props) {
  const {handleLogout} = props;
  const dialogTitleText = 'HP Anyware for Windows 365';

  return (
    <GatekeeperDialog open title={dialogTitleText}>
      <div>
        <MuiDialogContent className={classes.dialogContent}>
          <Typography className={classes.dialogContentText}>
            Sorry, you do not currently have the necessary permissions to access
            this application.
            <br />
            <br />
            Please contact your local administrator for assistance.
          </Typography>
        </MuiDialogContent>
        <MuiDialogActions>
          <Button onClick={handleLogout} variant="contained">
            Log Out
          </Button>
        </MuiDialogActions>
      </div>
    </GatekeeperDialog>
  );
}

AdminUserDialog.propTypes = {
  handleLogout: PropTypes.func.isRequired,
};
