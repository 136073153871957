import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import classNames from 'classnames';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import MuiDialogActions from '@mui/material/DialogActions';
import MuiDialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';

import GenericLink from '../common/GenericLink';
import config from '../../config';
import {isEmpty, handleLogout} from '../../utils/utils';
import GatekeeperDialog, {classes} from './GatekeeperDialog';
import {updateTenantStatus} from '../../redux/actions/tenantActions';

export default function UnlockDashboardDialog() {
  const [acceptedPrivacyPolicy, setAcceptedPrivacyPolicy] = useState(false);
  const [acceptedEula, setAcceptedEula] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [localUser, setLocalUser] = useState('');
  const [unlockButtonLoading, setUnlockButtonLoading] = useState(false);

  const dispatch = useDispatch();

  const {isLoading: tenantStatusLoading, tenantStatus} = useSelector(
    (state) => state.tenantStatus
  );
  const {
    isInitialized: authIsInitialized,
    isLoading: authStatusLoading,
    authStatus: {
      user: {username},
    },
  } = useSelector((state) => state.authStatus);
  const requirePrivacyPolicy = !tenantStatus?.privacyPolicy?.accepted;
  const requireEula = !tenantStatus?.eula?.accepted;
  const {AWM_EULA_LINK, PRIVACY_STATEMENT_LINK} = config;

  useEffect(() => {
    if (authIsInitialized && !authStatusLoading && !tenantStatusLoading) {
      setIsLoading(false);
    } else {
      setIsLoading(true);
    }
  }, [authIsInitialized, authStatusLoading, tenantStatusLoading]);

  useEffect(() => {
    if (!isLoading) {
      setLocalUser(username);
    }
  }, [isLoading, username, localUser]);

  const handleUnlock = async (event) => {
    event.preventDefault();
    setUnlockButtonLoading(true);

    const changes = {};
    if (requirePrivacyPolicy) {
      changes.privacyPolicyAccepted = acceptedPrivacyPolicy;
    }
    if (requireEula) {
      changes.eulaAccepted = acceptedEula;
    }

    if (!isEmpty(changes)) {
      // on click logic to update the tenant status
      const updatedTenantStatus = {
        ...tenantStatus,
        privacyPolicy: {
          ...tenantStatus.privacyPolicy,
          accepted:
            tenantStatus.privacyPolicy?.accepted ||
            changes.privacyPolicyAccepted,
          userPrincipalName: localUser,
          url: PRIVACY_STATEMENT_LINK,
        },
        eula: {
          ...tenantStatus.eula,
          accepted: tenantStatus.eula?.accepted || changes.eulaAccepted,
          userPrincipalName: localUser,
          url: AWM_EULA_LINK,
        },
      };
      dispatch(updateTenantStatus(updatedTenantStatus));
    }
    setUnlockButtonLoading(false);
  };

  const toggleAcceptedPrivacyPolicy = () =>
    setAcceptedPrivacyPolicy(!acceptedPrivacyPolicy);
  const toggleAcceptedEula = () => setAcceptedEula(!acceptedEula);

  const requireUnlock = requirePrivacyPolicy || requireEula;
  const enableUnlock =
    (!requirePrivacyPolicy || acceptedPrivacyPolicy) &&
    (!requireEula || acceptedEula);

  const dialogTitleText = 'Unlock HP Anyware for Windows 365';

  function renderPrivacyPolicyReq() {
    return (
      <>
        <Checkbox
          checked={acceptedPrivacyPolicy}
          onChange={toggleAcceptedPrivacyPolicy}
          color="primary"
        />
        I have read and accepted HP&apos;s&nbsp;
        <GenericLink url={PRIVACY_STATEMENT_LINK}>Privacy Policy</GenericLink>
        .
        <br />
      </>
    );
  }

  function renderEulaReq() {
    return (
      <>
        <Checkbox
          checked={acceptedEula}
          onChange={toggleAcceptedEula}
          color="primary"
        />
        I have read and accepted HP Anyware&apos;s&nbsp;
        <GenericLink url={AWM_EULA_LINK}>
          End User License Agreement
        </GenericLink>
        .
      </>
    );
  }

  if (isLoading) {
    return null;
  }

  return (
    <GatekeeperDialog open={requireUnlock} title={dialogTitleText}>
      <form onSubmit={handleUnlock}>
        <MuiDialogContent className={classes.dialogContent}>
          <Typography className={classes.dialogContentText}>
            Please complete the following actions to unlock HP Anyware for
            Windows 365.
            <br />
            <br />
          </Typography>
          {requirePrivacyPolicy && renderPrivacyPolicyReq()}
          {requireEula && renderEulaReq()}
        </MuiDialogContent>
        <MuiDialogActions>
          {!unlockButtonLoading && (
            <Button onClick={handleLogout} variant="contained">
              Log Out
            </Button>
          )}

          {!unlockButtonLoading && (
            <Button
              type="submit"
              disabled={!enableUnlock}
              color="primary"
              variant="contained"
            >
              Unlock
            </Button>
          )}

          {unlockButtonLoading && (
            <Button
              className={classNames(
                classes.buttons,
                classes.buttonUnlockLoading
              )}
              variant="contained"
            >
              <>
                <CircularProgress className={classes.loadingSpinner} />
                Unlocking HP Anyware for Windows 365...
              </>
            </Button>
          )}
        </MuiDialogActions>
      </form>
    </GatekeeperDialog>
  );
}
