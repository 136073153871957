import {groupActions} from '../actions/groupActions';

const initialState = {
  groups: [],
  groupMembers: {},
  stateCounter: 0,
  isLoading: false,
  error: null,
};

// eslint-disable-next-line default-param-last
const groupsReducer = (state = initialState, action) => {
  switch (action.type) {
    case groupActions.FETCH_GROUPS_START:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case groupActions.FETCH_GROUP_MEMBER_START:
      return {
        ...state,
        groupMembers: {
          ...state.groupMembers,
          [action.payload.groupId]: {isLoading: true, members: []},
        },
      };
    case groupActions.UPDATE_GROUP_STATUS_START:
    case groupActions.FETCH_GROUP_STATUS_START:
      return {
        ...state,
        groups: {
          ...state.groups,
          groups: state.groups.groups.map((group) =>
            group.id === action.payload.groupId
              ? {...group, isUpdating: true}
              : group
          ),
        },
      };
    case groupActions.FETCH_GROUPS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        groups: action.payload,
        error: null,
      };
    case groupActions.FETCH_GROUP_MEMBER_SUCCESS:
      return {
        ...state,
        groupMembers: {
          ...state.groupMembers,
          [action.payload.groupId]: {
            isLoading: false,
            members: action.payload.members,
          },
        },
      };
    case groupActions.UPDATE_GROUP_STATUS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        groups: {
          groups: state.groups.groups.map((group) =>
            group.id === action.payload.groupId
              ? {
                  ...group,
                  provisionStatus: action.payload.provisionStatus,
                  isUpdating: false,
                }
              : group
          ),
        },
        error: null,
        stateCounter: state.stateCounter + 1,
      };
    case groupActions.FETCH_GROUP_STATUS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        groups: {
          ...state.groups,
          groups: state.groups.groups.map((group) =>
            group.id === action.payload.groupId
              ? {
                  ...group,
                  provisionStatus: action.payload.provisionStatus,
                  isUpdating: false,
                }
              : group
          ),
        },
      };
    case groupActions.FETCH_GROUPS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        stateCounter: state.stateCounter + 1,
      };
    case groupActions.FETCH_GROUP_MEMBER_FAILURE:
      return {
        ...state,
        groupMembers: {
          ...state.groupMembers,
          [action.payload.groupId]: {
            isLoading: false,
            error: action.payload.error,
            members: [],
          },
        },
      };
    case groupActions.UPDATE_GROUP_STATUS_FAILURE:
    case groupActions.FETCH_GROUP_STATUS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
        groups: {
          ...state.groups,
          groups: state.groups.groups.map((group) =>
            group.id === action.payload.groupId
              ? {...group, isUpdating: false}
              : group
          ),
        },
      };
    default:
      return state;
  }
};

export default groupsReducer;
