import PropTypes from 'prop-types';
import {TableCell, TableRow, Chip, Box} from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import AutorenewOutlinedIcon from '@mui/icons-material/AutorenewOutlined';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const StatusMap = {
  notProvisioned: 'Not Provisioned',
  provisioning: 'Provisioning',
  provisioned: 'Provisioned',
  inGracePeriod: 'In Grace-period',
  deprovisioning: 'Deprovisioning',
  failed: 'Failed to Provision',
  provisionedWithWarnings: 'Provisioned with Warnings',
  resizing: 'Resizing',
  restoring: 'Restoring',
  pendingProvision: 'Pending Provision',
  unknownFutureValue: 'Unknown',
  movingRegion: 'Moving Region',
  resizePendingLicense: 'Resize Pending',
};

const InstallStatusMap = {
  installed: (
    <Chip
      icon={<CheckCircleOutlineIcon />}
      label="Installed"
      color="success"
      variant="outlined"
    />
  ),
  notInstalled: (
    <Chip
      icon={<HighlightOffOutlinedIcon />}
      label="Not Installed"
      color="default"
      variant="outlined"
    />
  ),
  installing: (
    <Chip
      icon={<AutorenewOutlinedIcon />}
      label="Installing"
      color="primary"
      variant="outlined"
    />
  ),
  uninstalling: (
    <Chip
      icon={<SettingsBackupRestoreIcon />}
      label="Uninstalling"
      color="warning"
      variant="outlined"
    />
  ),
  installFailed: (
    <Chip
      icon={<ErrorOutlineIcon />}
      label="Install Failed"
      color="error"
      variant="outlined"
    />
  ),
  uninstallFailed: (
    <Chip
      icon={<ErrorOutlineIcon />}
      label="Uninstall Failed"
      color="error"
      variant="outlined"
    />
  ),
};

function CloudPCRow({data}) {
  const hpInstallResult = data?.partnerAgentInstallResults?.find(
    (result) => result.partnerAgentName === 'hp'
  );
  const installStatusKey = hpInstallResult
    ? hpInstallResult.installStatus
    : 'notInstalled';

  return (
    <TableRow id={data.id}>
      <TableCell>{data.managedDeviceName}</TableCell>
      <TableCell>{data.userPrincipalName}</TableCell>
      <TableCell>{StatusMap[data.status]}</TableCell>
      <TableCell>
        <Box sx={{display: 'flex', justifyContent: 'flex-start', gap: '5px'}}>
          {InstallStatusMap[installStatusKey]}
        </Box>
      </TableCell>
    </TableRow>
  );
}

CloudPCRow.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string.isRequired,
    status: PropTypes.oneOf(Object.keys(StatusMap)).isRequired,
    userPrincipalName: PropTypes.string.isRequired,
    managedDeviceName: PropTypes.string.isRequired,
    partnerAgentInstallResults: PropTypes.arrayOf(
      PropTypes.shape({
        partnerAgentName: PropTypes.string.isRequired,
        installStatus: PropTypes.oneOf(Object.keys(InstallStatusMap))
          .isRequired,
        isThirdPartyPartner: PropTypes.bool.isRequired,
        retriable: PropTypes.bool.isRequired,
        errorMessage: PropTypes.string,
      })
    ).isRequired,
  }).isRequired,
};

export default CloudPCRow;
