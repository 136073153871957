import {styled} from '@mui/material/styles';
import createCache from '@emotion/cache';
import Sidebar from './sidebar/Sidebar';
import TopBar from './topbar/Topbar';
import MainPane from './MainPane';
import Banner from './Banner';
import {AbilityContext} from './Can';

import {useSelector} from 'react-redux';

import useAbility from '../../hooks/useAbility';
import useLocalUser from '../../hooks/useLocalUser';
import useTenantDeployment from '../../hooks/useTenantDeployment';

const PREFIX = 'AuthenticatedApp';

const classes = {
  root: `${PREFIX}-root`,
  flex: `${PREFIX}-flex`,
  appFrame: `${PREFIX}-appFrame`,
  grid: `${PREFIX}-grid`,
  drawerPaper: `${PREFIX}-drawerPaper`,
  content: `${PREFIX}-content`,
  mainPane: `${PREFIX}-mainPane`,
  logo: `${PREFIX}-logo`,
};

const Root = styled('div')(({theme}) => ({
  [`& .${classes.root}`]: {
    width: '100%',
    height: '100%',
    zIndex: 0,
    overflow: 'hidden',
    position: 'absolute',
    backgroundColor: theme.palette.surface,
  },

  [`& .${classes.flex}`]: {
    flex: 1,
  },

  [`& .${classes.appFrame}`]: {
    position: 'relative',
    display: 'flex',
    width: '100%',
    height: '100%',
  },

  [`& .${classes.grid}`]: {
    flexGrow: 1,
    container: {
      justifyContent: 'justify-xs-flex-end',
    },
  },

  [`& .${classes.drawerPaper}`]: {
    width: 280,
    backgroundColor: theme.palette.primary.dark,
    [theme.breakpoints.up('md')]: {
      position: 'relative',
      height: '100%',
    },
  },

  [`& .${classes.content}`]: {
    width: '100%',
    height: 'calc(100% - 80px)',
    marginTop: '80px',
    paddingTop: 0,
    paddingBottom: theme.spacing(2),
    overflow: 'auto',
  },

  [`& .${classes.mainPane}`]: {
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
  },

  [`& .${classes.logo}`]: {
    marginTop: 5,
    marginBottom: 5,
    height: 40,
  },
}));

createCache({
  key: 'key-emotion-css',

  nonce:
    document.querySelector('meta[property="csp-nonce"]')?.content ||
    '__SERVER__NONCE__',

  prepend: true,
});

function AuthenticatedApp() {
  const {announcements} = useSelector((state) => state.announcements);

  useLocalUser();
  useTenantDeployment();
  const ability = useAbility();

  const renderMainComponent = () => (
    <div className={classes.mainPane}>
      <Banner announcements={announcements} />
      <MainPane />
    </div>
  );

  return (
    <Root>
      <AbilityContext.Provider value={ability}>
        <div className={classes.root}>
          <div className={classes.appFrame}>
            <TopBar />
            <Sidebar />
            <main className={classes.content}>{renderMainComponent()}</main>
          </div>
        </div>
      </AbilityContext.Provider>
    </Root>
  );
}

export default AuthenticatedApp;
