import {approvedTenantActions} from '../actions/approvedTenantActions';

const initialState = {
  isApproved: false,
  isInitialized: false,
  isLoading: false,
  error: null,
};

// eslint-disable-next-line default-param-last
const approvedTenantReducer = (state = initialState, action) => {
  switch (action.type) {
    case approvedTenantActions.FETCH_TENANT_APPROVAL_STATUS_START:
      return {
        ...state,
        isLoading: true,
      };
    case approvedTenantActions.FETCH_TENANT_APPROVAL_STATUS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isApproved: action.payload,
        isInitialized: true,
        error: null,
      };
    case approvedTenantActions.FETCH_TENANT_APPROVAL_STATUS_FAILURE:
      return {
        ...state,
        isLoading: false,
        isApproved: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default approvedTenantReducer;
