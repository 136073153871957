import {useState} from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import {
  CircularProgress,
  TableCell,
  TableRow,
  Menu,
  MenuItem,
} from '@mui/material';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import VerifiedOutlinedIcon from '@mui/icons-material/VerifiedOutlined';
import PendingIcon from '@mui/icons-material/Pending';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import ButtonBase from '@mui/material/ButtonBase';
import {updateGroupStatus} from '../../redux/actions/groupActions';
import {Can} from '../common/Can';
import {
  DISPLAY_STATUS_ACTIVATION_FAILED,
  DISPLAY_STATUS_DEACTIVATION_FAILED,
  DISPLAY_STATUS_LICENSED,
  DISPLAY_STATUS_NOT_LICENSED,
  DISPLAY_STATUS_PENDING,
  PARTNER_GROUP_STATUS_ACTIVATION_FAILED,
  PARTNER_GROUP_STATUS_DEACTIVATION_FAILED,
  PARTNER_GROUP_STATUS_LICENSED,
  PARTNER_GROUP_STATUS_PENDING,
} from '../../utils/constants';
import GroupMembersDialog from './GroupMembersDialog';

function GroupRow({data}) {
  const dispatch = useDispatch();
  const {isLoading, groups} = useSelector((state) => state.groups);
  const {isUpdating} = groups.groups.find((cur) => cur.id === data.id);
  const [groupAnchorEl, setGroupAnchorEl] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleGroupMenuOpen = (event) => {
    setGroupAnchorEl(event.currentTarget);
  };

  const handleGroupMenuClose = () => {
    setGroupAnchorEl(null);
  };

  const handleGroupAction = (action) => {
    dispatch(updateGroupStatus(data.id, action));
    handleGroupMenuClose();
  };

  const displayProvisionStatus = (status) => {
    switch (status) {
      case PARTNER_GROUP_STATUS_LICENSED:
        return DISPLAY_STATUS_LICENSED;
      case PARTNER_GROUP_STATUS_PENDING:
        return DISPLAY_STATUS_PENDING;
      case PARTNER_GROUP_STATUS_ACTIVATION_FAILED:
        return DISPLAY_STATUS_ACTIVATION_FAILED;
      case PARTNER_GROUP_STATUS_DEACTIVATION_FAILED:
        return DISPLAY_STATUS_DEACTIVATION_FAILED;
      default:
        return DISPLAY_STATUS_NOT_LICENSED;
    }
  };

  const renderProvisionIcon = (status) => {
    switch (status) {
      case PARTNER_GROUP_STATUS_LICENSED:
        return <VerifiedOutlinedIcon color="success" style={{marginLeft: 8}} />;
      case PARTNER_GROUP_STATUS_PENDING:
        return <PendingIcon color="action" style={{marginLeft: 8}} />;
      case PARTNER_GROUP_STATUS_ACTIVATION_FAILED:
      case PARTNER_GROUP_STATUS_DEACTIVATION_FAILED:
        return <ErrorOutlineIcon color="error" style={{marginLeft: 8}} />;
      default:
        return <CancelOutlinedIcon color="error" style={{marginLeft: 8}} />;
    }
  };

  const renderMenuIcon = (loading, enabled, showMenu) => {
    if (loading) {
      return <CircularProgress size={20} style={{marginLeft: 8}} />;
    }
    if (!showMenu) {
      return null;
    }
    const iconStyle = {
      marginLeft: 8,
      transform: groupAnchorEl ? 'rotate(180deg)' : 'rotate(0deg)',
    };
    return (
      <ExpandMoreIcon
        color={enabled ? 'action' : 'disabled'}
        style={iconStyle}
      />
    );
  };

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  return (
    <>
      <TableRow id={data.id}>
        <TableCell>
          <ButtonBase onClick={handleDialogOpen}>{data.displayName}</ButtonBase>
        </TableCell>
        <TableCell>{data.id}</TableCell>
        <TableCell>
          <Can I="update" a="group" passThrough>
            {(canUpdateGroup) => (
              <ButtonBase
                disabled={!canUpdateGroup}
                onClick={handleGroupMenuOpen}
                onKeyDown={(e) => e.key === 'Enter' && handleGroupMenuOpen(e)}
                style={{
                  paddingRight: canUpdateGroup ? '' : '5ch',
                }}
                data-testid="activation-menu"
              >
                <span>{displayProvisionStatus(data.provisionStatus)}</span>
                {renderProvisionIcon(data.provisionStatus)}
                {renderMenuIcon(
                  isUpdating,
                  !isLoading,
                  canUpdateGroup &&
                    data.provisionStatus !== PARTNER_GROUP_STATUS_PENDING
                )}
              </ButtonBase>
            )}
          </Can>
          <Menu
            anchorEl={groupAnchorEl}
            open={Boolean(groupAnchorEl)}
            onClose={handleGroupMenuClose}
            anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
            transformOrigin={{vertical: 'top', horizontal: 'right'}}
            sx={{'& .MuiPaper-root': {width: 150}}}
          >
            {displayProvisionStatus(data.provisionStatus) ===
              DISPLAY_STATUS_NOT_LICENSED && (
              <MenuItem
                data-testid="activate"
                onClick={() => handleGroupAction('activate')}
              >
                Activate
              </MenuItem>
            )}
            {data.provisionStatus === PARTNER_GROUP_STATUS_LICENSED && (
              <>
                <MenuItem
                  data-testid="deactivate"
                  onClick={() => handleGroupAction('deactivate')}
                >
                  Deactivate
                </MenuItem>
                <MenuItem onClick={() => handleGroupAction('refresh')}>
                  Refresh
                </MenuItem>
              </>
            )}
            {data.provisionStatus ===
              PARTNER_GROUP_STATUS_ACTIVATION_FAILED && (
              <MenuItem
                data-testid="activate-failed-state"
                onClick={() => handleGroupAction('activate')}
              >
                Activate
              </MenuItem>
            )}
            {data.provisionStatus ===
              PARTNER_GROUP_STATUS_DEACTIVATION_FAILED && (
              <MenuItem
                data-testid="deactivate-failed-state"
                onClick={() => handleGroupAction('deactivate')}
              >
                Deactivate
              </MenuItem>
            )}
          </Menu>
        </TableCell>
      </TableRow>
      <GroupMembersDialog
        open={dialogOpen}
        onClose={handleDialogClose}
        groupId={data.id}
        groupName={data.displayName}
      />
    </>
  );
}

// todo: make provisionStatus required
GroupRow.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string.isRequired,
    displayName: PropTypes.string.isRequired,
    provisionStatus: PropTypes.string,
  }).isRequired,
};

export default GroupRow;
