import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {enqueueSnackbar} from 'notistack';
import {fetchGroups, fetchGroupStatus} from '../../redux/actions/groupActions';
import {fetchTenantStatus} from '../../redux/actions/tenantActions';
import GroupRow from './GroupRow';
import PaginatedTable from '../common/PaginatedTable';
import {PARTNER_GROUP_STATUS_PENDING} from '../../utils/constants';

function Groups() {
  const dispatch = useDispatch();
  const {groups, isLoading, error, stateCounter} = useSelector(
    (state) => state.groups
  );

  useEffect(() => {
    dispatch(fetchTenantStatus());
  }, [dispatch]);

  useEffect(() => {
    const groupList = groups.groups;
    const pollPendingGroups = () => {
      groupList.forEach((group) => {
        if (group.provisionStatus === PARTNER_GROUP_STATUS_PENDING) {
          dispatch(fetchGroupStatus(group.id));
        }
      });
    };

    const interval = setInterval(pollPendingGroups, 10000);

    return () => clearInterval(interval);
  }, [dispatch, groups]);

  useEffect(() => {
    if (error) {
      enqueueSnackbar(error, {variant: 'error'});
    }
  }, [error, enqueueSnackbar]);

  const columns = [
    {
      id: 'name',
      name: 'Name',
    },
    {
      id: 'groupId',
      name: 'Group ID',
    },
    {
      id: 'licenseStatus',
      name: 'License Status',
    },
  ];

  return (
    <PaginatedTable
      tableTitle="Entra ID Groups"
      isLoading={isLoading}
      data={groups}
      columns={columns}
      fetchDispatch={fetchGroups}
      stateCounter={stateCounter}
      dataType="groups"
      RowObject={GroupRow}
    />
  );
}

export default Groups;
