import Grid from '@mui/material/Grid';
import {styled} from '@mui/material/styles';
import Hidden from '@mui/material/Hidden';
import {
  HOME_PAGE_INFO_TEXT,
  PAGE_TITLE,
  PRIVACY_STATEMENT_LINK,
  FOOTER_TEXT,
} from '../../utils/constants';
import anywareLogoWhite from '../../icons/anyware-logo-white.svg';
import anywareBackground from '../../icons/background.png';
import hpLogo from '../../icons/hp-logo.svg';
import LoginPanel from './LoginPanel';

const PREFIX = 'UnauthenticatedApp';

const classes = {
  rootSmall: `${PREFIX}-rootSmall`,
  casIconSmall: `${PREFIX}-casIconSmall`,
  awmIconLarge: `${PREFIX}-awmIconLarge`,
  hpLogo: `${PREFIX}-hpLogo`,
  gridContainer: `${PREFIX}-gridContainer`,
  contentContainer: `${PREFIX}-contentContainer`,
  welcomeTitle: `${PREFIX}-welcomeTitle`,
  descriptionText: `${PREFIX}-descriptionText`,
  sidebar: `${PREFIX}-sidebar`,
  flexGrow: `${PREFIX}-flexGrow`,
  link: `${PREFIX}-link`,
  sidebarItem: `${PREFIX}-sidebarItem`,
  sidebarTextOffset: `${PREFIX}-sidebarTextOffset`,
  sidebarItemTitle: `${PREFIX}-sidebarItemTitle`,
  sidebarItemSubtext: `${PREFIX}-sidebarItemSubtext`,
  privacyStatement: `${PREFIX}-privacyStatement`,
  backdrop: `${PREFIX}-backdrop`,
  footerText: `${PREFIX}-footerText`,
};

const Root = styled(Grid)(({theme}) => ({
  [`&.${classes.gridContainer}`]: {
    height: '100vh',
    minHeight: '100vh',
  },

  [`& .${classes.rootSmall}`]: {
    backgroundColor: theme.palette.primary.sidebar,
    height: '100%',
    minWidth: '400px',
  },

  [`& .${classes.casIconSmall}`]: {
    height: '60px',
    marginLeft: '10px',
    marginTop: '10px',
  },

  [`& .${classes.logoContainer}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginBottom: '20px',
  },

  [`& .${classes.hpLogo}`]: {
    height: '50px',
    alignSelf: 'center',
  },

  [`& .${classes.awmIconLarge}`]: {
    maxWidth: '200px',
    marginLeft: '10px',
    marginBottom: '3px',
    width: '100%',
    alignSelf: 'center',
  },

  [`& .${classes.welcomeTitle}`]: {
    fontSize: '3rem',
    fontWeight: 600,
    marginTop: '10px',
    lineHeight: '1.5',
  },

  [`& .${classes.descriptionText}`]: {
    fontSize: '1.25rem',
    marginTop: '20px',
  },

  [`& .${classes.sidebar}`]: {
    alignItems: 'flex-start',
    backgroundColor: '#0D47A1',
    backgroundImage: `url(${anywareBackground}), linear-gradient(to top, #1759ba, #2c74d8)`,
    backgroundPosition: 'top left',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'auto 40%, auto',
    color: '#fff',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },

  [`& .${classes.contentContainer}`]: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '60ch',
    margin: '0 auto',
  },

  [`& .${classes.flexGrow}`]: {flexGrow: 1},

  [`& .${classes.link}`]: {
    textDecoration: 'underline dotted',
  },

  [`& .${classes.sidebarItem}`]: {
    alignItems: 'center',
    color: '#fff',
    display: 'flex',
    fontSize: '22px',
    marginTop: '20px',
  },

  [`& .${classes.sidebarTextOffset}`]: {
    paddingLeft: '6px',
  },

  [`& .${classes.sidebarItemTitle}`]: {
    fontWeight: 500,
    marginLeft: '6px',
  },

  [`& .${classes.sidebarItemSubtext}`]: {
    fontSize: '14px',
    marginLeft: '30px',
  },

  [`& .${classes.privacyStatement}`]: {
    color: '#999',
    fontSize: '0.875rem',
    marginTop: 'auto',
    paddingLeft: '20px',
    paddingBottom: '20px',
    textAlign: 'center',
  },

  [`& .${classes.backdrop}`]: {
    backgroundColor: 'rgb(255, 255, 255)',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },

  [`& .${classes.footerText}`]: {
    textAlign: 'center',
    margin: 'auto',
    padding: '10px',
    border: '1px',
    borderRadius: '5px',
    backgroundColor: 'rgb(246,246,255)',
  },
}));

const sidebarItems = [];

function UnauthenticatedApp() {
  const renderSidebarItem = (item) => {
    const Icon = item.icon;
    return (
      <div key={item.title}>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={item.link}
          className={classes.sidebarItem}
        >
          <Icon />
          <div className={classes.sidebarItemTitle}>{item.title}</div>
        </a>
        <div className={classes.sidebarItemSubtext}>{item.subtext}</div>
      </div>
    );
  };

  return (
    <Root container direction="row" className={classes.gridContainer}>
      <Hidden only="xs">
        <Grid item xs={12} sm={12} lg={6}>
          <div className={classes.sidebar}>
            <div className={classes.flexGrow} />
            <div className={classes.contentContainer}>
              <div className={classes.logoContainer}>
                <img className={classes.hpLogo} alt="HP Logo" src={hpLogo} />
                <img
                  className={classes.awmIconLarge}
                  alt="HP Anyware Logo"
                  src={anywareLogoWhite}
                />
              </div>
              <div className={classes.welcomeTitle}>{PAGE_TITLE}</div>
              <div className={classes.descriptionText}>
                {HOME_PAGE_INFO_TEXT}
              </div>
            </div>
            <div className={classes.flexGrow} />
            {sidebarItems.map((item) => renderSidebarItem(item))}
            <div className={classes.flexGrow} />
          </div>
        </Grid>
      </Hidden>
      <Grid item xs={12} sm={12} lg={6}>
        <div className={classes.backdrop}>
          <div className={classes.flexGrow} />
          <LoginPanel />
          <div className={classes.flexGrow} />

          {(!!FOOTER_TEXT) && <div className={classes.footerText}>{FOOTER_TEXT}</div>}

          <div style={{flexGrow: 0.1}} />

          <div className={classes.privacyStatement}>
            © Copyright 2024 HP Development Company, L.P.
            <br />
            This application uses cookies. {' '}
            <a
              href={PRIVACY_STATEMENT_LINK}
              target="_blank"
              rel="noopener noreferrer"
              className={classes.link}
            >
              Privacy Policy
            </a>
          </div>
        </div>
      </Grid>
    </Root>
  );
}

export default UnauthenticatedApp;
