import keyMirror from 'keymirror';
import {get, post, put, del} from '../../api/Api';

export const licenseActions = keyMirror({
  FETCH_LICENSES_START: null,
  FETCH_LICENSES_SUCCESS: null,
  FETCH_LICENSES_FAILURE: null,
  SUBMIT_LICENSE_START: null,
  SUBMIT_LICENSE_SUCCESS: null,
  SUBMIT_LICENSE_FAILURE: null,
  ACTIVATE_LICENSE_START: null,
  ACTIVATE_LICENSE_SUCCESS: null,
  ACTIVATE_LICENSE_FAILURE: null,
  DELETE_LICENSE_START: null,
  DELETE_LICENSE_SUCCESS: null,
  DELETE_LICENSE_FAILURE: null,
});

// Action Creators

export const fetchLicensesStart = () => ({
  type: licenseActions.FETCH_LICENSES_START,
});

export const fetchLicensesSuccess = (licenses) => ({
  type: licenseActions.FETCH_LICENSES_SUCCESS,
  payload: licenses,
});

export const fetchLicensesFailure = (error) => ({
  type: licenseActions.FETCH_LICENSES_FAILURE,
  payload: error,
});

export const submitLicenseStart = () => ({
  type: licenseActions.SUBMIT_LICENSE_START,
});

export const submitLicenseSuccess = (license) => ({
  type: licenseActions.SUBMIT_LICENSE_SUCCESS,
  payload: license,
});

export const submitLicenseFailure = (error) => ({
  type: licenseActions.SUBMIT_LICENSE_FAILURE,
  payload: error,
});

export const activateLicenseStart = () => ({
  type: licenseActions.ACTIVATE_LICENSE_START,
});

export const activateLicenseSuccess = (license) => ({
  type: licenseActions.ACTIVATE_LICENSE_SUCCESS,
  payload: license,
});

export const activateLicenseFailure = (error) => ({
  type: licenseActions.ACTIVATE_LICENSE_FAILURE,
  payload: error,
});

export const deleteLicenseStart = () => ({
  type: licenseActions.DELETE_LICENSE_START,
});

export const deleteLicenseSuccess = (license) => ({
  type: licenseActions.DELETE_LICENSE_SUCCESS,
  payload: license,
});

export const deleteLicenseFailure = (error) => ({
  type: licenseActions.DELETE_LICENSE_FAILURE,
  payload: error,
});

// Thunk Actions

export const fetchLicenses = () => async (dispatch) => {
  dispatch(fetchLicensesStart());
  try {
    const response = await get({path: 'licensing'});
    dispatch(fetchLicensesSuccess(response.data));
  } catch (error) {
    dispatch(fetchLicensesFailure(error.data.message));
  }
};

export const submitLicense = (licenseCode) => async (dispatch) => {
  dispatch(submitLicenseStart());
  try {
    await post({
      path: 'licensing',
      data: {licenseKey: licenseCode},
    });
    dispatch(submitLicenseSuccess());
    dispatch(fetchLicenses()); // Refetch licenses after successful submission
  } catch (error) {
    dispatch(submitLicenseFailure(error.data.message));
  }
};

export const activateLicense = (licenseKey) => async (dispatch) => {
  dispatch(activateLicenseStart());
  try {
    await put({path: `licensing/${licenseKey}/activate`});
    dispatch(activateLicenseSuccess());
    dispatch(fetchLicenses());
  } catch (error) {
    dispatch(activateLicenseFailure(error.data.message));
  }
};

export const deleteLicense = (licenseKey) => async (dispatch) => {
  dispatch(deleteLicenseStart());
  try {
    await del({path: `licensing/${licenseKey}`});
    dispatch(deleteLicenseSuccess());
    dispatch(fetchLicenses());
  } catch (error) {
    dispatch(deleteLicenseFailure(error.data.message));
  }
};
