import {useState} from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import {
  CircularProgress,
  TableCell,
  TableRow,
  Menu,
  MenuItem,
} from '@mui/material';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import VerifiedOutlinedIcon from '@mui/icons-material/VerifiedOutlined';
import PendingIcon from '@mui/icons-material/Pending';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import ButtonBase from '@mui/material/ButtonBase';
import {
  updateUserRole,
  updateUserStatus,
} from '../../redux/actions/userActions';
import {Can} from '../common/Can';
import {
  PARTNER_USER_STATUS_LICENSED,
  PARTNER_USER_STATUS_PENDING,
  PARTNER_USER_STATUS_ACTIVATION_FAILED,
  PARTNER_USER_STATUS_DEACTIVATION_FAILED,
  ROLE_ADMINISTRATOR,
  ROLE_USER_ADMIN,
  ROLE_USER,
  DISPLAY_STATUS_ACTIVATION_FAILED,
  DISPLAY_STATUS_DEACTIVATION_FAILED,
  DISPLAY_STATUS_LICENSED,
  DISPLAY_STATUS_NOT_LICENSED,
  DISPLAY_STATUS_PENDING,
} from '../../utils/constants';
import UserGroupDialog from './UserGroupDialog';

function UserRow({data}) {
  const dispatch = useDispatch();
  const {isLoading, users} = useSelector((state) => state.users);
  const {updatingRole, updatingStatus} = users.users.find(
    (cur) => cur.id === data.id
  );
  const [userAnchorEl, setUserAnchorEl] = useState(null);
  const [roleAnchorEl, setRoleAnchorEl] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleUserMenuOpen = (event) => {
    setUserAnchorEl(event.currentTarget);
  };

  const handleUserMenuClose = () => {
    setUserAnchorEl(null);
  };

  const handleUserAction = (action) => {
    dispatch(updateUserStatus(data.id, action));
    handleUserMenuClose();
  };

  const handleRoleMenuOpen = (event) => {
    setRoleAnchorEl(event.currentTarget);
  };

  const handleRoleMenuClose = () => {
    setRoleAnchorEl(null);
  };

  const handleRoleAction = (action) => {
    dispatch(updateUserRole(data.id, action));
    handleRoleMenuClose();
  };

  const displayRoles = {
    [ROLE_ADMINISTRATOR]: 'Administrator',
    [ROLE_USER]: 'User',
    [ROLE_USER_ADMIN]: 'User Admin',
  };

  const displayProvisionStatus = (status) => {
    switch (status) {
      case PARTNER_USER_STATUS_LICENSED:
        return DISPLAY_STATUS_LICENSED;
      case PARTNER_USER_STATUS_PENDING:
        return DISPLAY_STATUS_PENDING;
      case PARTNER_USER_STATUS_ACTIVATION_FAILED:
        return DISPLAY_STATUS_ACTIVATION_FAILED;
      case PARTNER_USER_STATUS_DEACTIVATION_FAILED:
        return DISPLAY_STATUS_DEACTIVATION_FAILED;
      default:
        return DISPLAY_STATUS_NOT_LICENSED;
    }
  };

  const renderProvisionIcon = (status) => {
    switch (status) {
      case PARTNER_USER_STATUS_LICENSED:
        return <VerifiedOutlinedIcon color="success" style={{marginLeft: 8}} />;
      case PARTNER_USER_STATUS_PENDING:
        return <PendingIcon color="action" style={{marginLeft: 8}} />;
      case PARTNER_USER_STATUS_ACTIVATION_FAILED:
      case PARTNER_USER_STATUS_DEACTIVATION_FAILED:
        return <ErrorOutlineIcon color="error" style={{marginLeft: 8}} />;
      default:
        return <CancelOutlinedIcon color="error" style={{marginLeft: 8}} />;
    }
  };

  const isUUID = (str) => {
    const uuidRegex =
      /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
    return uuidRegex.test(str);
  };

  const displayProvisionMethod = (groups, provisionStatus) => {
    let message;
    if (displayProvisionStatus(provisionStatus) === DISPLAY_STATUS_NOT_LICENSED) {
      message = 'Not Activated';
    } else {
      const uuids = groups.filter((group) => isUUID(group));
      const individual = groups.includes('Individual');
      const groupActivated = (uuids.length > 0)
      if (groupActivated) {
        message = individual ? `Group (${uuids.length}), Direct Activated` : `Group (${uuids.length}) Activated`;
      } else {
        message = individual ? 'Direct Activated' : 'Not Activated';
      }
    }
    return message
  };

  const renderMenuIcon = (loading, enabled, showMenu) => {
    if (loading) {
      return <CircularProgress size={20} style={{marginLeft: 8}} />;
    }
    if (!showMenu) {
      return null;
    }
    const iconStyle = {
      marginLeft: 8,
      transform: userAnchorEl ? 'rotate(180deg)' : 'rotate(0deg)',
    };
    return (
      <ExpandMoreIcon
        color={enabled ? 'action' : 'disabled'}
        style={iconStyle}
      />
    );
  };

  const hasUUID = data.groups.some(isUUID);
  const hasIndividual = data.groups.includes('Individual');

  return (
    <>
      <TableRow id={data.id}>
        <TableCell>{data.displayName}</TableCell>
        <TableCell>{data.userPrincipalName}</TableCell>
        <TableCell>
          {hasUUID ? (
            <ButtonBase onClick={handleDialogOpen}>
              {displayProvisionMethod(data.groups, data.provisionStatus)}
            </ButtonBase>
          ) : (
            <span>
              {displayProvisionMethod(data.groups, data.provisionStatus)}
            </span>
          )}
        </TableCell>
        <TableCell>
          <Can I="update" a="user" passThrough>
            {(canUpdateUser) => (
              <ButtonBase
                disabled={!canUpdateUser || isLoading}
                onClick={handleUserMenuOpen}
                onKeyDown={(e) => e.key === 'Enter' && handleUserMenuOpen(e)}
                style={{
                  paddingRight: canUpdateUser ? '' : '5ch',
                }}
                data-testid="activation-menu"
              >
                <span>{displayProvisionStatus(data.provisionStatus)}</span>
                {renderProvisionIcon(data.provisionStatus)}
                {renderMenuIcon(
                  updatingStatus,
                  !isLoading,
                  canUpdateUser &&
                    data.provisionStatus !== PARTNER_USER_STATUS_PENDING
                )}
              </ButtonBase>
            )}
          </Can>
          <Menu
            anchorEl={userAnchorEl}
            open={Boolean(userAnchorEl)}
            onClose={handleUserMenuClose}
            anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
            transformOrigin={{vertical: 'top', horizontal: 'right'}}
            sx={{'& .MuiPaper-root': {width: 150}}}
          >
            {displayProvisionStatus(data.provisionStatus) ===
              DISPLAY_STATUS_NOT_LICENSED && (
              <MenuItem
                data-testid="activate"
                onClick={() => handleUserAction('activate')}
              >
                Activate
              </MenuItem>
            )}
            {data.provisionStatus === PARTNER_USER_STATUS_LICENSED && (
              <>
                <MenuItem
                  data-testid="deactivate"
                  onClick={() =>
                    handleUserAction(
                      hasUUID && !hasIndividual ? 'activate' : 'deactivate'
                    )
                  }
                >
                  {hasUUID && !hasIndividual ? 'Activate' : 'Deactivate'}
                </MenuItem>
                {hasIndividual && (
                  <MenuItem onClick={() => handleUserAction('refresh')}>
                    Refresh
                  </MenuItem>
                )}
              </>
            )}
            {data.provisionStatus === PARTNER_USER_STATUS_ACTIVATION_FAILED && (
              <MenuItem
                data-testid="activate-failed-state"
                onClick={() => handleUserAction('activate')}
              >
                Activate
              </MenuItem>
            )}
            {data.provisionStatus ===
              PARTNER_USER_STATUS_DEACTIVATION_FAILED && (
              <MenuItem
                data-testid="deactivate-failed-state"
                onClick={() => handleUserAction('deactivate')}
              >
                Deactivate
              </MenuItem>
            )}
          </Menu>
        </TableCell>
        <Can I="promote" a="user" passThrough>
          {(canPromoteUser) => (
            <TableCell>
              <ButtonBase
                disabled={
                  data.role === ROLE_ADMINISTRATOR ||
                  !canPromoteUser ||
                  isLoading
                }
                onClick={handleRoleMenuOpen}
                onKeyDown={(e) => e.key === 'Enter' && handleRoleMenuOpen(e)}
                style={{
                  alignItems: 'center',
                  paddingRight: canPromoteUser ? '' : '5ch',
                }}
                data-testid="role-menu"
              >
                <span>{displayRoles[data.role]}</span>
                {renderMenuIcon(
                  updatingRole,
                  !(isLoading || data.role === ROLE_ADMINISTRATOR),
                  canPromoteUser
                )}
              </ButtonBase>
              {data.role !== ROLE_ADMINISTRATOR && (
                <Menu
                  anchorEl={roleAnchorEl}
                  open={Boolean(roleAnchorEl)}
                  onClose={handleRoleMenuClose}
                  anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                  transformOrigin={{vertical: 'top', horizontal: 'right'}}
                  sx={{'& .MuiPaper-root': {width: 200}}}
                >
                  {data.role === ROLE_USER && (
                    <MenuItem
                      data-testid="promote-user"
                      onClick={() => handleRoleAction('promote')}
                    >
                      Grant User Admin
                    </MenuItem>
                  )}
                  {data.role === ROLE_USER_ADMIN && (
                    <MenuItem
                      data-testid="demote-user"
                      onClick={() => handleRoleAction('demote')}
                    >
                      Revoke User Admin
                    </MenuItem>
                  )}
                </Menu>
              )}
            </TableCell>
          )}
        </Can>
      </TableRow>
      <UserGroupDialog
        open={dialogOpen}
        onClose={handleDialogClose}
        userId={data.id}
        userName={data.displayName}
      />
    </>
  );
}

UserRow.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string.isRequired,
    displayName: PropTypes.string.isRequired,
    userPrincipalName: PropTypes.string.isRequired,
    provisionStatus: PropTypes.string.isRequired,
    role: PropTypes.string.isRequired,
    groups: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
};

export default UserRow;
