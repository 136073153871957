import {useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Typography from '@mui/material/Typography';
import {
  Box,
  CircularProgress,
  Fab,
  IconButton,
  Paper,
  Switch,
  Tooltip,
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import SettingsIcon from '@mui/icons-material/Settings';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import BuildIcon from '@mui/icons-material/Build';
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {green} from '@mui/material/colors';
import {enqueueSnackbar} from 'notistack';
import {
  disableIntegrations,
  enableIntegrations,
  fetchTenantDeploymentStatus,
} from '../../redux/actions/tenantActions';
import {Can} from '../common/Can';

function Tenant() {
  const dispatch = useDispatch();
  const {
    tenantStatus,
    deploymentStatus,
    tenantTransitioning,
    error,
    isLoading,
  } = useSelector((state) => state.tenantStatus);
  const [processType, setProcessType] = useState(
    localStorage.getItem('processType') || 'null'
  );
  const [toggleKey, setToggleKey] = useState(0);

  useEffect(() => {
    localStorage.setItem('processType', processType);
  }, [processType]);

  const isBusy = useMemo(() => {
    const isEnabling = isLoading || tenantTransitioning;
    const isDisabling = isLoading || tenantTransitioning;
    return isEnabling || isDisabling;
  }, [tenantTransitioning, deploymentStatus?.linkProvisionStatus]);

  useEffect(() => {
    const fetchStatus = () => dispatch(fetchTenantDeploymentStatus());

    // Fetch status immediately on mount.
    fetchStatus();

    // Set up an interval to fetch status repeatedly if 'isBusy' is true.
    const intervalId = isBusy ? setInterval(fetchStatus, 3000) : null;

    // Return a cleanup function to clear the interval when the component unmounts or 'isBusy' changes.
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [isBusy, dispatch]);

  useEffect(() => {
    if (error || tenantStatus?.provisionError) {
      const errorMessage =
        error ||
        (tenantStatus?.provisionError && tenantStatus.provisionError !== ''
          ? tenantStatus.provisionError
          : null);
      enqueueSnackbar(errorMessage, {variant: 'error', preventDuplicate: true});
    }
  }, [error, tenantStatus, enqueueSnackbar]);

  const handleSwitchChange = () => {
    const newProcessType =
      tenantStatus?.provisionStatus === 'Enabled' ? 'disable' : 'enable';
    setProcessType(newProcessType);
    dispatch(
      newProcessType === 'enable' ? enableIntegrations() : disableIntegrations()
    );
    setToggleKey((prevKey) => prevKey + 1);
  };

  /*
  The following steps are in strict order for the enable process:
  1. Partner Integration
  2. Deployment Creation
  3. FA Configuration
  4. Link Service Enablement
  5. DSA and Enrollment Key Configuration
  */
  const steps = useMemo(() => {
    if (processType === 'null') return [];
    return processType === 'enable'
      ? [
          {
            id: 'partnerIntegration',
            name: 'Enabling Windows 365 Partner Integration',
            icon: <IntegrationInstructionsIcon />,
            condition: tenantStatus?.partnerApiStatus === 'Enabled',
          },
          {
            id: 'deploymentCreation',
            name: 'Create Anyware Manager Deployment',
            icon: <AddCircleOutlineIcon />,
            condition: !!deploymentStatus?.deploymentId,
          },
          {
            id: 'faConfiguration',
            name: 'Configure Anyware Manager Deployment',
            icon: <SettingsIcon />,
            condition: !!deploymentStatus?.faEnabled,
          },
          {
            id: 'linkServiceEnablement',
            name: 'Enabling Link Service for Anyware Manager Deployment',
            icon: <VpnKeyIcon />,
            condition:
              deploymentStatus?.linkProvisionStatus &&
              deploymentStatus?.linkProvisionStatus !== 'pending',
          },
          {
            id: 'dsaAndEnrollmentConfig',
            name: 'Configure Anyware Manager for Automated Agent Installs',
            icon: <BuildIcon />,
            condition:
              deploymentStatus?.dsaName &&
              deploymentStatus?.dsaName !== 'pending' &&
              deploymentStatus?.enrollmentKeyName &&
              deploymentStatus?.enrollmentKeyName !== 'pending',
          },
        ]
      : [
          {
            id: 'deleteDeployment',
            name: 'Delete Anyware Manager Deployment',
            icon: <HighlightOffOutlinedIcon />,
            condition: !deploymentStatus?.deploymentId,
          },
          {
            id: 'disableIntegration',
            name: 'Disabling Windows 365 Partner Integration',
            icon: <HighlightOffOutlinedIcon />,
            condition: tenantStatus?.partnerApiStatus === 'Disabled',
          },
        ];
  }, [tenantStatus?.provisionStatus, deploymentStatus, isBusy, processType]);

  const fabStyle = {
    width: '28px',
    height: '28px',
    minHeight: '0',
    pointerEvents: 'none',
  };

  return (
    <Paper
      sx={{
        padding: '20px',
        display: 'flex',
        flexDirection: 'column',
        gap: '5px',
      }}
    >
      <Typography variant="overline">
        Enable or Disable Integrations
        <Tooltip title="Configure the integration settings for your tenant.">
          <IconButton
            aria-label="More information"
            sx={{
              verticalAlign: 'initial',
              '& .MuiSvgIcon-root': {fontSize: '1rem'},
            }}
          >
            <InfoOutlinedIcon color="primary" />
          </IconButton>
        </Tooltip>
      </Typography>

      <Can I="update" a="tenant" passThrough>
        {(canUpdateTenant) => (
          <Switch
            checked={
              tenantStatus?.provisionStatus === 'Enabled' ||
              processType === 'enable'
            }
            onChange={handleSwitchChange}
            disabled={!canUpdateTenant || isBusy}
            data-testid="tenant-switch"
          />
        )}
      </Can>
      <div key={toggleKey}>
        {steps.map((step) => (
          <Box
            key={step.id}
            sx={{
              display: 'flex',
              alignItems: 'center',
              width: '100%',
              my: 1,
              position: 'relative',
            }}
          >
            <Fab
              aria-label={step.name}
              color="primary"
              sx={{
                ...fabStyle,
                boxShadow: step.condition ? '2' : undefined,
                bgcolor: step.condition ? green[500] : 'primary',
              }}
            >
              {step.condition ? <CheckIcon /> : step.icon}
            </Fab>
            {!step.condition && (
              <CircularProgress
                size={34}
                sx={{
                  marginLeft: 1,
                  color: green[500],
                  position: 'absolute',
                  top: -3,
                  left: -11,
                  zIndex: 1,
                }}
              />
            )}
            <Typography
              data-testid="integration-step"
              variant="subtitle2"
              sx={{ml: 2}}
            >
              {step.name}
            </Typography>
          </Box>
        ))}
      </div>
    </Paper>
  );
}

export default Tenant;
