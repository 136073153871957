import {useEffect, useState} from 'react';
import {
  Alert,
  Autocomplete,
  Box,
  IconButton,
  InputAdornment,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from '@mui/material';
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';
import {styled} from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import {useDispatch, useSelector} from 'react-redux';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import SwapVertOutlinedIcon from '@mui/icons-material/SwapVertOutlined';
import PropTypes from 'prop-types';

const PREFIX = 'PaginatedTable';
const SEARCH_KEY = 'searchHistory';

const classes = {
  root: `${PREFIX}-root`,
  headerContainer: `${PREFIX}-headerContainer`,
  paginationContainer: `${PREFIX}-paginationContainer`,
  searchBar: `${PREFIX}-searchBar`,
  tableContainer: `${PREFIX}-tableContainer`,
};

const Root = styled('div')(({theme}) => ({
  [`&.${classes.root}`]: {
    width: '90%',
    height: '100%',
    margin: '0 auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white',
    flexDirection: 'column',
  },

  [`& .${classes.headerContainer}`]: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    padding: '0 20px',
    margin: '10px 0',
  },

  [`& .${classes.searchBar}`]: {
    padding: '10px 15px',
    width: '250px',
    border: '2px solid #ddd',
    borderRadius: '5px',
    outline: 'none',
    boxShadow: '0 2px 5px rgba(0,0,0,0.1)',
    transition: 'border-color 0.3s',
    '&:focus': {
      borderColor: theme.palette.primary.main,
    },
    '&::placeholder': {
      color: theme.palette.text.secondary,
    },
  },

  [`& .${classes.paginationContainer}`]: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  [`& .${classes.tableContainer}`]: {
    '&::-webkit-scrollbar': {
      width: '6px',
      height: '6px',
    },
    '&::-webkit-scrollbar-track': {
      background: '#fff',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.primary.main,
      borderRadius: '8px',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: theme.palette.primary.dark,
    },
  },
}));

function PaginatedTable({
  tableTitle,
  fetchDispatch,
  stateCounter,
  isLoading,
  columns,
  data,
  dataType,
  RowObject,
}) {
  const dispatch = useDispatch();
  const {tenantStatus} = useSelector((state) => state.tenantStatus);
  const [currentPage, setCurrentPage] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  const [pageSize, setPageSize] = useState(15);
  const [totalCount, setTotalCount] = useState(0);
  const [dropdownSearch, setDropdownSearch] = useState('');
  const canSearch = !isLoading && tenantStatus?.consentStatus;
  const [orderBy, setOrderBy] = useState('');

  const fetchTableData = () => {
    if (canSearch) {
      saveSearchQuery(searchQuery);
      dispatch(fetchDispatch(currentPage, pageSize, searchQuery, orderBy));
    }
  };

  useEffect(() => {
    fetchTableData();
  }, [
    dispatch,
    currentPage,
    pageSize,
    orderBy,
    stateCounter,
    tenantStatus?.consentStatus,
  ]);

  useEffect(() => {
    if (currentPage === 0 && data.totalCount !== undefined) {
      setTotalCount(data.totalCount);
    }
  }, [data]);

  useEffect(() => {
    if (canSearch) {
      dispatch(fetchDispatch(currentPage, pageSize, dropdownSearch));
    }
  }, [dropdownSearch]);

  const saveSearchQuery = (searchQuery) => {
    const searchHistory = localStorage.getItem(SEARCH_KEY);
    let searchArr = [];
    if (searchHistory) {
      searchArr = JSON.parse(searchHistory);
    }
    if (searchArr.length >= 5) {
      searchArr.shift();
    }
    searchArr.push(searchQuery);
    //filter out empty strings and duplicates
    searchArr = searchArr.filter(
      (item, index) => item && searchArr.indexOf(item) === index
    );
    localStorage.setItem(SEARCH_KEY, JSON.stringify(searchArr));
  };

  const getSearchHistory = () => {
    const searchHistory = localStorage.getItem(SEARCH_KEY);
    return searchHistory ? JSON.parse(searchHistory) : [];
  };

  const handleChangeSearch = (event, value) => {
    setSearchQuery(event.target.value);
    setCurrentPage(0);
  };

  const handleAutocompleteChange = (_, value) => {
    setSearchQuery(value);
    setDropdownSearch(value);
  };

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };

  const handleRefresh = () => {
    if (tenantStatus?.consentStatus)
      dispatch(fetchDispatch(currentPage, pageSize, searchQuery));
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      fetchTableData();
    }
  };

  const handleSort = (key) => {
    let sortKey = key;
    switch (key) {
      case 'userPrincipalName':
      case 'assignedUser':
        sortKey = 'userPrincipalName';
        break;

      case 'name':
        if (tableTitle === 'Cloud PCs') {
          sortKey = 'managedDeviceName';
        } else {
          sortKey = 'displayName';
        }
        break;

      default:
        break;
    }

    if (orderBy.startsWith(sortKey)) {
      const newOrderBy = orderBy.endsWith('desc') ? sortKey : `${sortKey} desc`;
      setOrderBy(newOrderBy);
    } else {
      setOrderBy(sortKey);
    }
  };

  // Conditional content rendering based on loading states
  let tableRows;

  if (!tenantStatus?.consentStatus) {
    tableRows = (
      <TableRow sx={{height: '200px'}}>
        <TableCell colSpan={columns.length} sx={{verticalAlign: 'middle'}}>
          <Alert severity="warning" data-testid="consent-warning">
            You have not yet consented to the application!
          </Alert>
        </TableCell>
      </TableRow>
    );
  } else {
    tableRows = data[dataType]?.map((row) => (
      <RowObject key={row.id} data={row} />
    ));
  }

  const setDirectionIcon = (columnId) => {
    if (orderBy.startsWith(columnId)) {
      return orderBy.endsWith('desc') ? (
        <ArrowDropDownIcon />
      ) : (
        <ArrowDropUpIcon />
      );
    }
    return <SwapVertOutlinedIcon />;
  };

  return (
    <Root className={classes.root}>
      <div className={classes.headerContainer}>
        <Typography variant="h4">{tableTitle}</Typography>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            gap: 2,
          }}
        >
          {tenantStatus?.consentStatus && (
            <IconButton onClick={handleRefresh} aria-label="refresh">
              <RefreshOutlinedIcon />
            </IconButton>
          )}
          <Autocomplete
            freeSolo
            options={getSearchHistory()}
            disableClearable
            forcePopupIcon={false}
            sx={{width: '300px'}}
            onChange={handleAutocompleteChange}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                onChange={handleChangeSearch}
                onKeyDown={handleKeyDown}
                placeholder="Search"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        sx={{margin: -1}}
                        aria-label="search"
                        onClick={fetchTableData}
                        edge="end"
                      >
                        <SearchOutlinedIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
        </Box>
      </div>
      <TableContainer
        className={classes.tableContainer}
        sx={{maxHeight: 'calc(100vh - 250px)'}}
        component={Paper}
      >
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => {
                const nonSortingColumn =
                  column.id === 'groupId' ||
                  column.id === 'activationPath' ||
                  column.id === 'anywareAgent';
                return (
                  <TableCell
                    key={column.id}
                    style={{
                      fontWeight: 'bold',
                      cursor: nonSortingColumn ? 'default' : 'pointer',
                    }}
                    onClick={() => {
                      if (!nonSortingColumn) {
                        handleSort(column.id);
                      }
                    }}
                  >
                    {column.name}
                    {!nonSortingColumn && setDirectionIcon(column.id)}
                  </TableCell>
                );
              })}
            </TableRow>
            {isLoading && (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  sx={{
                    verticalAlign: 'middle',
                    padding: 0,
                    'border-bottom': 0,
                  }}
                >
                  <LinearProgress />
                </TableCell>
              </TableRow>
            )}
          </TableHead>
          <TableBody>{tableRows}</TableBody>
        </Table>
      </TableContainer>
      <div className={classes.paginationContainer}>
        <TablePagination
          component="div"
          count={totalCount}
          page={currentPage}
          onPageChange={handleChangePage}
          rowsPerPage={pageSize}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[15, 25, 50, 100]}
        />
      </div>
    </Root>
  );
}
PaginatedTable.propTypes = {
  tableTitle: PropTypes.string.isRequired,
  fetchDispatch: PropTypes.func.isRequired,
  stateCounter: PropTypes.number,
  isLoading: PropTypes.bool.isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  data: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.shape({
      totalCount: PropTypes.number,
      [PropTypes.string]: PropTypes.arrayOf(PropTypes.object),
    }),
  ]).isRequired,
  dataType: PropTypes.string.isRequired,
  RowObject: PropTypes.elementType.isRequired,
};
PaginatedTable.defaultProps = {
  stateCounter: 0,
};

export default PaginatedTable;
