import {useEffect} from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  CircularProgress,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';
import {useDispatch, useSelector} from 'react-redux';
import {createSelector} from 'reselect';
import {fetchGroupMembers} from '../../redux/actions/groupActions';

/*
 * Refine the selector to return the group members for a specific group to resolve the following warning:
 * Selector unknown returned a different result when called with the same parameters. This can lead to unnecessary rerenders.
 */
const selectGroupMembersState = (state) => state.groups.groupMembers;

const makeSelectGroupMembers = (groupId) =>
  createSelector(
    [selectGroupMembersState],
    (groupMembers) => groupMembers[groupId] || {isLoading: true, members: []}
  );

function GroupMembersDialog({open, onClose, groupId, groupName}) {
  const dispatch = useDispatch();
  const selectGroupMembers = makeSelectGroupMembers(groupId);
  const groupMembersState = useSelector(selectGroupMembers);

  useEffect(() => {
    if (open) {
      dispatch(fetchGroupMembers(groupId));
    }
  }, [open, dispatch, groupId]);

  const {isLoading, members} = groupMembersState;

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>{groupName} Members</DialogTitle>
      <DialogContent>
        <Typography variant="body2" color="textSecondary" paragraph>
          To manage the members of this group, please use the Microsoft Intune
          Admin Center.
        </Typography>
        {isLoading ? (
          <CircularProgress />
        ) : (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>User Principal Name</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {members.map((member) => (
                  <TableRow key={member.id}>
                    <TableCell>{member.displayName}</TableCell>
                    <TableCell>{member.userPrincipalName}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}

GroupMembersDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  groupId: PropTypes.string.isRequired,
  groupName: PropTypes.string.isRequired,
};

export default GroupMembersDialog;
