import {useSelector} from 'react-redux';
import {createMongoAbility} from '@casl/ability';

const useAbility = () => {
  const {
    authStatus: {
      user: {scopes},
    },
  } = useSelector((state) => state.authStatus);

  return createMongoAbility(scopes);
};

export default useAbility;
