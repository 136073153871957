import {
  ButtonBase,
  Grid,
  Step,
  StepContent,
  StepLabel,
  Stepper,
} from '@mui/material';
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Gatekeeper from '../gatekeeper/Gatekeeper';
import AppConsent from './AppConsent';
import License from './License';
import Tenant from './Tenant';
import Status from './Status';

import {fetchLicenses} from '../../redux/actions/licenseActions';
import {fetchAnnouncements} from '../../redux/actions/announcementActions';

function Dashboard() {
  const [activeStep, setActiveStep] = useState(0);
  const [currentStep, setCurrentStep] = useState(0);
  const dispatch = useDispatch();
  const {licenses} = useSelector((state) => state.licenses);
  const {tenantStatus} = useSelector((state) => state.tenantStatus);

  // Define steps with their components and completion conditions
  const steps = [
    {
      label: 'Grant Consent',
      component: <AppConsent />,
      isComplete: tenantStatus?.consentStatus,
    },
    {
      label: 'Add Anyware License',
      component: <License />,
      isComplete: licenses.length > 0,
    },
    {
      label: 'Setup Integrations',
      component: <Tenant />,
      isComplete: tenantStatus?.provisionStatus === 'Enabled',
    },
  ];

  useEffect(() => {
    dispatch(fetchAnnouncements());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchLicenses());
  }, [dispatch]);

  useEffect(() => {
    // Update activeStep based on completion conditions
    let newActiveStep = activeStep;
    steps.forEach((step, index) => {
      if (step.isComplete && index >= newActiveStep) {
        newActiveStep = index + 1;
      }
    });

    if (newActiveStep !== activeStep) {
      setActiveStep(newActiveStep);
      // Automatically move to the next step if the current step is completed
      if (currentStep < newActiveStep) {
        setCurrentStep(newActiveStep);
      }
    }
  }, [tenantStatus, licenses, steps, activeStep, currentStep]);

  const handleStepClick = (stepIndex) => {
    if (stepIndex <= activeStep) {
      setCurrentStep(stepIndex);
    }
  };

  return (
    <Gatekeeper>
      <div style={{height: '15px'}} />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Stepper
            activeStep={currentStep}
            orientation="vertical"
            sx={{margin: 2, position: 'sticky', top: 0, width: '800px'}}
          >
            {steps.map((step, index) => (
              <Step
                key={step.label}
                completed={step.isComplete}
                data-testid={`step-${index}`}
                sx={{
                  '& .MuiStepLabel-root .MuiStepLabel-label': {
                    cursor: index <= activeStep ? 'pointer' : 'default',
                    color:
                      index <= activeStep || index === currentStep
                        ? 'rgba(0, 0, 0, 0.87)'
                        : '#c0c0c0',
                  },
                  '& .MuiStepLabel-root .Mui-completed': {
                    color: 'green',
                  },
                  '& .MuiStepIcon-root': {
                    color:
                      index <= activeStep || index === currentStep
                        ? 'primary.main'
                        : '#c0c0c0',
                  },
                }}
              >
                <ButtonBase
                  onClick={() => handleStepClick(index)}
                  disableRipple
                  sx={{
                    width: '100%',
                    justifyContent: 'flex-start',
                  }}
                >
                  <StepLabel>{step.label}</StepLabel>
                </ButtonBase>
                <StepContent>{step.component}</StepContent>
              </Step>
            ))}
          </Stepper>
        </Grid>
        <Grid item xs={12}>
          <Status />
        </Grid>
      </Grid>
    </Gatekeeper>
  );
}

export default Dashboard;
