import {licenseActions} from '../actions/licenseActions';

const initialState = {
  licenses: [],
  isLoading: false,
  error: null,
};

// eslint-disable-next-line default-param-last
const licensesReducer = (state = initialState, action) => {
  switch (action.type) {
    case licenseActions.FETCH_LICENSES_START:
    case licenseActions.SUBMIT_LICENSE_START:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case licenseActions.FETCH_LICENSES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        licenses: action.payload,
      };
    case licenseActions.SUBMIT_LICENSE_SUCCESS:
      // No specific state update needed here since fetchLicenses will be called next
      return {
        ...state,
        isLoading: false,
      };
    case licenseActions.FETCH_LICENSES_FAILURE:
    case licenseActions.SUBMIT_LICENSE_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default licensesReducer;
