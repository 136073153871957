import {useRef, useState} from 'react';
import {styled} from '@mui/material/styles';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import PropTypes from 'prop-types';
import KebabMenuItem from '../kebab-menu/KebabMenuItem';

const PREFIX = 'UserMenu';

const classes = {
  userMenuButton: `${PREFIX}-userMenuButton`,
  userMenuDropdown: `${PREFIX}-userMenuDropdown`,
  userMenuIcon: `${PREFIX}-userMenuIcon`,
  userMenuText: `${PREFIX}-userMenuText`,
};

const Root = styled('div')(({theme}) => ({
  [`& .${classes.userMenuButton}`]: {
    height: '48px',
  },

  [`& .${classes.userMenuDropdown}`]: {
    marginTop: '-10px',
  },

  [`& .${classes.userMenuIcon}`]: {
    color: theme.palette.primary.main,
    height: '20px',
    width: '20px',
  },

  [`& .${classes.userMenuText}`]: {
    color: 'rgba(0,0,0,0.87)',
    padding: '0px 16px',
  },
}));

const buttonOrigin = {
  vertical: 'bottom',
  horizontal: 'center',
};

const menuOrigin = {
  vertical: 'top',
  horizontal: 'center',
};

function UserMenu({handleSignout, userName}) {
  const userMenu = useRef(null);

  const [anchorEl, setAnchorEl] = useState(null);
  const [userMenuOpen, setUserMenuOpen] = useState(false);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
    setUserMenuOpen(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setUserMenuOpen(false);
  };

  const getUserMenuWidth = () =>
    userMenu.current ? userMenu.current.clientWidth : 100;

  return (
    <Root ref={userMenu}>
      <ListItem
        data-testid="user-menu"
        button
        onClick={handleOpen}
        className={classes.userMenuButton}
      >
        <img src="" alt="" />
        <ListItemText
          className={classes.userMenuText}
          primary={userName || ''}
        />
        {userMenuOpen ? (
          <ExpandLess className={classes.userMenuIcon} />
        ) : (
          <ExpandMore className={classes.userMenuIcon} />
        )}
      </ListItem>
      <Menu
        className={classes.userMenuDropdown}
        classes={{paper: classes.paper}}
        anchorEl={anchorEl}
        anchorOrigin={buttonOrigin}
        transformOrigin={menuOrigin}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <KebabMenuItem
          enableClick
          menuText="Sign out"
          width={getUserMenuWidth()}
          onClick={handleSignout}
          dataTestId="sign-out-button"
        />
      </Menu>
    </Root>
  );
}

UserMenu.propTypes = {
  handleSignout: PropTypes.func.isRequired,
  userName: PropTypes.string,
};

UserMenu.defaultProps = {
  userName: '',
};

export default UserMenu;
