import {authActions} from '../actions/authActions';
import {userActions} from '../actions/userActions';

const initialState = {
  authStatus: {
    isAuthenticated: false,
    user: {
      tenantId: '',
      username: '',
      scopes: [],
    },
    expiresOn: null,
  },
  isInitialized: false,
  isLoading: false,
  error: null,
};

// eslint-disable-next-line default-param-last
const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case authActions.FETCH_AUTH_STATUS_START:
    case authActions.FETCH_SESSION_SCOPES_START:
    case userActions.FETCH_AUTHENTICATED_USER_START:
      return {
        ...state,
        isLoading: true,
      };
    case authActions.FETCH_AUTH_STATUS_SUCCESS:
      return {
        ...state,
        authStatus: {
          ...initialState.authStatus, // Prevent unauthenticated state from removing user fields from the store.
          ...action.payload,
          user: {
            ...initialState.authStatus.user,
            ...state.authStatus.user,
            tenantId: action.payload?.user?.tenantId,
            username: action.payload?.user?.username,
          },
        },
        isInitialized: true,
        isLoading: false,
        error: null,
      };
    case authActions.FETCH_SESSION_SCOPES_SUCCESS:
      return {
        ...state,
        authStatus: {
          ...state.authStatus,
          user: {
            ...state.authStatus.user,
            scopes: action.payload,
          },
        },
        isLoading: false,
        error: null,
      };
    case userActions.FETCH_AUTHENTICATED_USER_SUCCESS:
      return {
        ...state,
        authStatus: {
          ...state.authStatus,
          user: {
            ...state.authStatus.user,
          },
        },
        isLoading: false,
        error: null,
      };
    case authActions.FETCH_AUTH_STATUS_FAILURE:
    case userActions.FETCH_AUTHENTICATED_USER_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default authReducer;
