import {styled} from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import {Link} from 'react-router-dom';

const PREFIX = 'DefaultPage';

const classes = {
  container: `${PREFIX}-container`,
};

const Root = styled('div')(({theme}) => ({
  [`&.${classes.container}`]: theme.createPage,
}));

function DefaultPage() {
  return (
    <Root className={classes.container}>
      <Typography variant="h6">URL not found</Typography>
      <p>
        We couldn&rsquo;t find the page you were looking for. Try clicking one
        of the links below:
      </p>
      <ul>
        <li>
          <Link to="/app/dashboard">Dashboard</Link>
        </li>
        <li>
          <Link to="/app/cloudpc">Workstations</Link>
        </li>
      </ul>
    </Root>
  );
}

export default DefaultPage;
