import keyMirror from 'keymirror';
import {get} from '../../api/Api';

export const cloudPCActions = keyMirror({
  FETCH_CLOUDPCS_START: null,
  FETCH_CLOUDPCS_SUCCESS: null,
  FETCH_CLOUDPCS_FAILURE: null,
});

// Fetch CloudPCs Actions

export const fetchCloudPCsStart = () => ({
  type: cloudPCActions.FETCH_CLOUDPCS_START,
});

export const fetchCloudPCsSuccess = (cloudPCs) => ({
  type: cloudPCActions.FETCH_CLOUDPCS_SUCCESS,
  payload: cloudPCs,
});

export const fetchCloudPCsFailure = (error) => ({
  type: cloudPCActions.FETCH_CLOUDPCS_FAILURE,
  payload: error,
});

// Thunk Actions

export const fetchCloudPCs =
  (page, pageSize, searchTerm, orderBy) => async (dispatch) => {
    dispatch(fetchCloudPCsStart());
    try {
      const response = await get({
        path: `cloudpcs?page=${page}&pageSize=${pageSize}&searchTerm=${searchTerm}&orderBy=${orderBy}`,
      });
      dispatch(fetchCloudPCsSuccess(response.data));
    } catch (error) {
      dispatch(fetchCloudPCsFailure(error.data.message));
    }
  };
