import './App.css';
import {Route, Routes} from 'react-router-dom';
import Layout from './components/Layout';
import UnauthenticatedApp from './components/home/UnauthenticatedApp';
import AuthenticatedApp from './components/common/AuthenticatedApp';
import RequireAuth from './components/common/RequireAuth';
import useAuth from './hooks/useAuth';

function App() {
  useAuth();

  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<UnauthenticatedApp />} />
        <Route
          path="/app/*"
          element={
            <RequireAuth>
              <AuthenticatedApp />
            </RequireAuth>
          }
        />
      </Route>
    </Routes>
  );
}

export default App;
