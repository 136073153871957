import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {enqueueSnackbar} from 'notistack';
import {fetchUsers, fetchUserStatus} from '../../redux/actions/userActions';
import {fetchTenantStatus} from '../../redux/actions/tenantActions';
import UserRow from './UserRow';
import PaginatedTable from '../common/PaginatedTable';
import {PARTNER_USER_STATUS_PENDING} from '../../utils/constants';

function Users() {
  const dispatch = useDispatch();
  const {users, isLoading, error, stateCounter} = useSelector(
    (state) => state.users
  );

  useEffect(() => {
    dispatch(fetchTenantStatus());
  }, [dispatch]);

  useEffect(() => {
    const userList = users.users;
    const pollPendingUsers = () => {
      userList?.forEach((user) => {
        if (user.provisionStatus === PARTNER_USER_STATUS_PENDING) {
          dispatch(fetchUserStatus(user.id));
        }
      });
    };

    const interval = setInterval(pollPendingUsers, 10000);

    return () => clearInterval(interval);
  }, [dispatch, users]);

  useEffect(() => {
    if (error) {
      enqueueSnackbar(error, {variant: 'error'});
    }
  }, [error, enqueueSnackbar]);

  const columns = [
    {
      id: 'name',
      name: 'Name',
    },
    {
      id: 'userPrincipalName',
      name: 'User Principal Name',
    },
    {
      id: 'activationPath',
      name: 'Activation Path',
    },
    {
      id: 'licenseStatus',
      name: 'License Status',
    },
    {
      id: 'role',
      name: 'Role',
    },
  ];

  return (
    <PaginatedTable
      tableTitle="Entra ID Users"
      isLoading={isLoading}
      data={users}
      columns={columns}
      fetchDispatch={fetchUsers}
      stateCounter={stateCounter}
      dataType="users"
      RowObject={UserRow}
    />
  );
}

export default Users;
