import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {fetchAuthStatus} from '../redux/actions/authActions';

const useAuth = () => {
  const dispatch = useDispatch();
  const {isInitialized, isLoading, authStatus} = useSelector(
    (state) => state.authStatus
  );

  useEffect(() => {
    dispatch(fetchAuthStatus());

    const interval = setInterval(() => {
      dispatch(fetchAuthStatus());
    }, 60 * 1000); // Check every minute, adjust as needed
    return () => clearInterval(interval);
  }, [dispatch]);

  return {...authStatus, isInitialized, isLoading};
};

export default useAuth;
