import PropTypes from 'prop-types';

import {styled} from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import MuiDialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';

const PREFIX = 'Gatekeeper';

export const classes = {
  buttons: `${PREFIX}-buttons`,
  buttonUnlockLoading: `${PREFIX}-buttonUnlockLoading`,
  dialogTitleBar: `${PREFIX}-dialogTitleBar`,
  dialogTitleText: `${PREFIX}-dialogTitleText`,
  dialogContent: `${PREFIX}-dialogContent`,
  dialogContentText: `${PREFIX}-dialogContentText`,
  loadingSpinner: `${PREFIX}-loadingSpinner`,
};

const StyledDialog = styled(Dialog)(() => ({
  [`& .${classes.buttons}`]: {
    textTransform: 'none',
    fontWeight: 'normal',
    letterSpacing: '0',
    lineHeight: '0.75rem',
    padding: '9px 2px',
    fontSize: '0.8125rem',
  },

  [`& .${classes.buttonUnlockLoading}`]: {
    backgroundColor: '#FFF',
    color: '#0070D2',
    padding: '9px 14px',
    cursor: 'wait',
    '&:hover': {
      backgroundColor: '#FFF',
      color: '#0070D2',
    },
  },

  [`& .${classes.dialogTitleBar}`]: {
    backgroundColor: '#164A85',
    padding: 0,
  },

  [`& .${classes.dialogTitleText}`]: {
    color: '#FFF',
    fontSize: '1.25rem',
    lineHeight: '25px',
    textShadow: '0 0 4px 0 rgba(3,46,97,0.5)',
    margin: '12px 25px',
  },

  [`& .${classes.dialogContent}`]: {
    backgroundColor: '#FFFFFF',
  },

  [`& .${classes.dialogContentText}`]: {
    fontSize: '1rem',
  },

  [`& .${classes.loadingSpinner}`]: {
    width: '12px',
    height: '12px',
    marginRight: '10px',
  },
}));

function GatekeeperDialogTitle({children}) {
  return (
    <MuiDialogTitle className={classes.dialogTitleBar}>
      <Typography className={classes.dialogTitleText}>{children}</Typography>
    </MuiDialogTitle>
  );
}

GatekeeperDialogTitle.propTypes = {
  children: PropTypes.node.isRequired,
};

export default function GatekeeperDialog({open, title, children}) {
  return (
    <StyledDialog open={open}>
      <GatekeeperDialogTitle>{title}</GatekeeperDialogTitle>
      {children}
    </StyledDialog>
  );
}

GatekeeperDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};
