import {userActions} from '../actions/userActions';

const initialState = {
  users: [],
  userGroups: {},
  stateCounter: 0,
  isLoading: false,
  error: null,
};

// eslint-disable-next-line default-param-last
const usersReducer = (state = initialState, action) => {
  switch (action.type) {
    case userActions.FETCH_USERS_START:
    case userActions.FETCH_USER_GROUPS_START:
    case userActions.FETCH_ACTIVATED_USERS_START:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case userActions.UPDATE_USER_ROLE_START:
      return {
        ...state,
        users: {
          ...state.users,
          users: state.users.users.map((user) =>
            user.id === action.payload.userId
              ? {...user, updatingRole: true}
              : user
          ),
        },
      };
    case userActions.UPDATE_USER_STATUS_START:
    case userActions.FETCH_USER_STATUS_START:
      return {
        ...state,
        users: {
          ...state.users,
          users: state.users.users.map((user) =>
            user.id === action.payload.userId
              ? {...user, updatingStatus: true}
              : user
          ),
        },
      };
    case userActions.FETCH_USERS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        users: {
          users: action.payload.users.map((user) => ({
            ...user,
            updatingRole: false,
            updatingStatus: false,
          })),
          totalCount: action.payload.totalCount,
        },
        error: null,
      };
    case userActions.FETCH_USER_GROUPS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        userGroups: {
          ...state.userGroups,
          [action.payload.userId]: action.payload.groups,
        },
        error: null,
      };
    case userActions.FETCH_ACTIVATED_USERS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        users: action.payload,
        error: null,
      };
    case userActions.UPDATE_USER_STATUS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        users: {
          users: state.users.users.map((user) =>
            user.id === action.payload.userId
              ? {
                  ...user,
                  provisionStatus: action.payload.provisionStatus,
                  updatingStatus: false,
                }
              : user
          ),
        },
        error: null,
        stateCounter: state.stateCounter + 1,
      };
    case userActions.UPDATE_USER_ROLE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        users: {
          users: state.users.users.map((user) =>
            user.id === action.payload.userId
              ? {...user, role: action.payload.role, updatingRole: false}
              : user
          ),
        },
        stateCounter: state.stateCounter + 1,
      };
    case userActions.FETCH_USER_STATUS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        users: {
          ...state.users,
          users: state.users.users.map((user) =>
            user.id === action.payload.userId
              ? {
                  ...user,
                  provisionStatus: action.payload.provisionStatus,
                  updatingStatus: false,
                }
              : user
          ),
        },
      };
    case userActions.FETCH_USERS_FAILURE:
    case userActions.FETCH_USER_GROUPS_FAILURE:
    case userActions.FETCH_ACTIVATED_USERS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        stateCounter: state.stateCounter + 1,
      };
    case userActions.UPDATE_USER_ROLE_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
        users: {
          ...state.users,
          users: state.users.users.map((user) =>
            user.id === action.payload.userId
              ? {...user, updatingRole: false}
              : user
          ),
        },
      };
    case userActions.UPDATE_USER_STATUS_FAILURE:
    case userActions.FETCH_USER_STATUS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
        users: {
          ...state.users,
          users: state.users.users.map((user) =>
            user.id === action.payload.userId
              ? {...user, updatingStatus: false}
              : user
          ),
        },
      };
    default:
      return state;
  }
};

export default usersReducer;
