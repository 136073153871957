import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {styled} from '@mui/material/styles';
import msLogo from '../../icons/Microsoft_logo.svg';
import LoginButton from './LoginButton';

const PREFIX = 'LoginPanel';

const classes = {
  internalWrapper: `${PREFIX}-internalWrapper`,
  root: `${PREFIX}-root`,
  rootBorder: `${PREFIX}-rootBorder`,
  rootShadow: `${PREFIX}-rootShadow`,
  buttonWrapper: `${PREFIX}-buttonWrapper`,
  underlineLink: `${PREFIX}-underlineLink`,
  statusLink: `${PREFIX}-statusLink`,
  statusSection: `${PREFIX}-statusSection`,
  loginButton: `${PREFIX}-loginButton`,
  standaloneButton: `${PREFIX}-standaloneButton`,
  samlText: `${PREFIX}-samlText`,
  loadingSpinner: `${PREFIX}-loadingSpinner`,
  panelTitle: `${PREFIX}-panelTitle`,
  panelDescription: `${PREFIX}-panelDescription`,
  validationSuccess: `${PREFIX}-validationSuccess`,
  validationError: `${PREFIX}-validationError`,
  validationText: `${PREFIX}-validationText`,
  updateButton: `${PREFIX}-updateButton`,
};

const Root = styled('div')(({theme}) => ({
  [`& .${classes.internalWrapper}`]: {
    margin: '0px auto',
    maxWidth: '600px',
    minWidth: '300px',
    padding: '40px 0px',
    width: '100%',
  },

  [`& .${classes.root}`]: {
    backgroundColor: '#fff',
    borderRadius: '5px',
    width: 'calc(100% - 150px)',
    margin: '0px auto',
    minWidth: '370px',
    maxWidth: '700px',
    boxShadow: '0px 0px 10px rgba(0,0,0,0.2)',
  },

  [`& .${classes.rootBorder}`]: {
    // border: '1px solid rgba(0,0,0,0.2)',
  },

  [`& .${classes.rootShadow}`]: {
    boxShadow: '0px 0px 10px #fff',
  },

  [`& .${classes.buttonWrapper}`]: {
    color: theme.palette.surface,
    marginInline: '30px',
    fontSize: '12px',
  },

  [`& .${classes.underlineLink}`]: {
    borderBottom: '1.5px dotted rgba(0,0,0,0.4)',
    color: theme.palette.surface,
  },

  [`& .${classes.statusLink}`]: {
    color: '#023E51',
    fontSize: '16px',
    fontWeight: 500,
  },

  [`& .${classes.statusSection}`]: {
    marginTop: '60px',
  },

  [`& .${classes.loginButton}`]: {
    textTransform: 'none',
    width: '100%',
    minHeight: '3.1rem',
    position: 'relative',
    backgroundColor: theme.palette.primary.main,
    padding: '10px',
    justifyContent: 'center',
    borderRadius: 2,
    border: 'none',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
    '&:active': {
      backgroundColor: theme.palette.primary.main,
    },
  },

  [`& .${classes.standaloneButton}`]: {
    marginTop: '40px',
  },

  [`& .${classes.samlText}`]: {
    color: '#fff',
    fontSize: '1rem',
    fontWeight: 500,
    lineHeight: 1.5,
    letterSpacing: '0.00938em',
  },

  [`& .${classes.loadingSpinner}`]: {
    color: 'white',
  },

  [`& .${classes.panelTitle}`]: {
    fontSize: '2em',
    fontWeight: 400,
    paddingBottom: '40px',
    textAlign: 'center',
  },

  [`& .${classes.panelDescription}`]: {
    marginInline: '30px',
  },

  [`& .${classes.validationSuccess}`]: {
    color: theme.palette.success.main,
  },

  [`& .${classes.validationError}`]: {
    color: theme.palette.error.main,
  },

  [`& .${classes.validationText}`]: {
    marginLeft: '0.5rem',
  },

  [`& .${classes.updateButton}`]: {
    width: '100%',
    marginTop: '3rem',
  },
}));

function LoginPanel() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const {
    isInitialized,
    isLoading,
    authStatus: {isAuthenticated},
  } = useSelector((state) => state.authStatus);

  useEffect(() => {
    if (isInitialized && !isLoading && isAuthenticated) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, [isInitialized, isLoading, isAuthenticated]);

  if (isLoggedIn) {
    window.location.href = '/app';
  }

  const renderLoginForm = () => (
    <>
      <div className={classes.panelTitle}>Sign in to HP Anyware</div>
      <div className={classes.buttonWrapper}>
        <LoginButton
          idp="azure"
          onClick={() => {
            window.location.href = '/auth/login';
          }}
          iconSrc={msLogo}
          text="Sign in with Microsoft Entra ID"
        />
      </div>
    </>
  );

  return (
    <Root>
      <div className={classes.internalWrapper}>{renderLoginForm()}</div>
    </Root>
  );
}

export default LoginPanel;
