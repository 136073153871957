import {useCallback, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import MuiDialogActions from '@mui/material/DialogActions';
import MuiDialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';

import GenericLink from '../common/GenericLink';
import GatekeeperDialog, {classes} from './GatekeeperDialog';

export default function UnapprovedTenantDialog(props) {
  const {redirectUrl, redirectSeconds, handleLogout} = props;
  const [timer, setTimer] = useState(redirectSeconds);
  const [unit, setUnit] = useState('seconds');
  const timeoutCallback = useCallback(
    () => setTimer((currTimer) => currTimer - 1),
    []
  );

  useEffect(() => {
    if (timer > 0) {
      // TODO the first decrement is skipped, e.g. displays "10, 8, 7, etc."
      setTimeout(timeoutCallback, 1000);
      if (timer === 1) {
        setUnit('second');
      } else {
        setUnit('seconds');
      }
    } else {
      window.location.href = redirectUrl;
    }
  }, [timer, timeoutCallback]);

  const dialogTitleText = 'HP Anyware for Windows 365';
  const redirectLink = (
    <GenericLink url={redirectUrl}>contact page</GenericLink>
  );

  return (
    <GatekeeperDialog open title={dialogTitleText}>
      <div>
        <MuiDialogContent className={classes.dialogContent}>
          <Typography className={classes.dialogContentText}>
            Thank you for your interest in HP Anyware for Windows 365.
            <br />
            Access is limited to registered customers only.
            <br />
            Please visit our {redirectLink} or click the Sign Up button to
            proceed.
            <br />
            You will be automatically redirected to this page in {timer} {unit}.
          </Typography>
        </MuiDialogContent>
        <MuiDialogActions>
          <Button
            variant="contained"
            onClick={() => {
              window.location.href = redirectUrl;
            }}
          >
            Sign Up
          </Button>
          <Button onClick={handleLogout} variant="contained">
            Log Out
          </Button>
        </MuiDialogActions>
      </div>
    </GatekeeperDialog>
  );
}

UnapprovedTenantDialog.propTypes = {
  redirectUrl: PropTypes.string.isRequired,
  redirectSeconds: PropTypes.number.isRequired,
  handleLogout: PropTypes.func.isRequired,
};
