import Drawer, {drawerClasses} from '@mui/material/Drawer';
import {styled} from '@mui/material/styles';
import Hidden from '@mui/material/Hidden';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Tooltip from '@mui/material/Tooltip';
import CloseIcon from '@mui/icons-material/ArrowBackIos';
import OpenIcon from '@mui/icons-material/ArrowForwardIos';
import DashboardIcon from '@mui/icons-material/Dashboard';
import WorkstationsIcon from '@mui/icons-material/DesktopWindowsOutlined';
import UsersIcon from '@mui/icons-material/Group';
import GroupsIcon from '@mui/icons-material/Groups';
import classNames from 'classnames';
import {useDispatch, useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import useBreakpoint from '../../../hooks/useBreakpoint';
import {
  closeSidebar,
  toggleSidebar,
} from '../../../redux/actions/sidebarActions';
import anywareIcon from '../../../icons/anyware-only-logo-white.svg';
import anywareLogo from '../../../icons/anyware-logo-white.svg';
import {
  AD_GROUPS_LINK,
  AD_USERS_LINK,
  DASHBOARD_LINK,
  WORKSTATIONS_LINK,
  BREAKPOINT_SM,
  BREAKPOINT_MD,
} from '../../../utils/constants';

const PREFIX = 'Sidebar';

const classes = {
  toolbar: `${PREFIX}-toolbar`,
  drawer: `${PREFIX}-drawer`,
  drawerOpen: `${PREFIX}-drawerOpen`,
  drawerClose: `${PREFIX}-drawerClose`,
  drawerHeader: `${PREFIX}-drawerHeader`,
  logo: `${PREFIX}-logo`,
  logoSmall: `${PREFIX}-logoSmall`,
  listItemText: `${PREFIX}-listItemText`,
  currentListItem: `${PREFIX}-currentListItem`,
  toggleButton: `${PREFIX}-toggleButton`,
  icon: `${PREFIX}-icon`,
  spacer: `${PREFIX}-spacer`,
  versionLabel: `${PREFIX}-versionLabel`,
  versionText: `${PREFIX}-versionText`,
};

const CustomDrawer = styled(Drawer)(({theme}) => ({
  [`&.${drawerClasses.root}`]: {
    [`& .${classes.drawer}`]: {
      width: 240,
      flexShrink: 0,
    },

    [`& .${classes.drawerOpen}`]: {
      width: 240,
      overflowX: 'hidden',
      border: '1px solid rgba(0,0,0,0.12)',
      borderLeft: 0,
      borderBottom: 0,
      borderTop: 0,
      backgroundColor: 'rgb(27, 74, 140)',
      backgroundImage: 'linear-gradient(to top, #1759ba, #2c74d8)',
    },

    [`& .${classes.drawerClose}`]: {
      width: 56,
      overflowX: 'hidden',
      backgroundColor: 'rgb(27, 74, 140)',
      backgroundImage: 'linear-gradient(to top, #1759ba, #2c74d8)',
    },

    [`&.${classes.drawerHeader}`]: {
      display: 'flex',
      justifyContent: 'flex-start',
      padding: 0,
      minHeight: 80,
      cursor: 'pointer',
    },

    [`& .${classes.logo}`]: {
      textAlign: 'left',
      verticalAlign: 'middle',
      margin: 15,
      height: 45,
      whiteSpace: 'nowrap',
    },

    [`& .${classes.logoSmall}`]: {
      height: 45,
      margin: 5,
      marginTop: 16,
    },

    [`& .${classes.listItemText}`]: {
      color: 'white',
      fontWeight: 500,
      whiteSpace: 'nowrap',
    },

    [`& .${classes.currentListItem}`]: {
      backgroundColor: theme.palette.primary.main,
    },
    [`& .${classes.toggleButton}`]: {marginLeft: 'auto'},
    [`& .${classes.icon}`]: {color: 'white'},
    [`& .${classes.spacer}`]: {flexGrow: 1},

    [`& .${classes.versionLabel}`]: {
      color: 'white',
      fontWeight: 600,
      marginLeft: '6px',
    },

    [`& .${classes.versionText}`]: {
      color: 'white',
      margin: '5px 0px 5px 12px',
      wordBreak: 'break-word',
    },
  },
}));

const Root = styled('div')(() => ({
  [`& .${classes.drawer}`]: {
    width: 240,
  },

  [`& .${classes.drawerOpen}`]: {
    width: 240,
  },

  [`& .${classes.drawerClose}`]: {
    width: 56,
  },
}));

function Sidebar() {
  const dispatch = useDispatch();
  const currentPage = useSelector((state) => state.router);
  const {open} = useSelector((state) => state.sidebar);

  useBreakpoint(BREAKPOINT_SM, () => dispatch(closeSidebar()));
  useBreakpoint(BREAKPOINT_MD, () => dispatch(closeSidebar()), 'down');

  const listItems = [
    {
      id: 'dashboard',
      name: 'Dashboard',
      link: DASHBOARD_LINK,
      Icon: DashboardIcon,
    },
    {
      id: 'remote-workstations',
      name: 'Cloud PCs',
      link: WORKSTATIONS_LINK,
      Icon: WorkstationsIcon,
    },
    {
      id: 'users',
      name: 'Entra ID Users',
      link: AD_USERS_LINK,
      Icon: UsersIcon,
    },
    {
      id: 'groups',
      name: 'Entra ID Groups',
      link: AD_GROUPS_LINK,
      Icon: GroupsIcon,
    },
  ];

  const teradiciLogo = () => (
    <img
      src={open ? anywareLogo : anywareIcon}
      className={classNames(classes.logo, {[classes.logoSmall]: !open})}
      alt="HP Anyware for Windows 365 solution"
    />
  );

  const handleListItemClick = () => {
    if (window.innerWidth <= BREAKPOINT_SM) {
      dispatch(closeSidebar());
    }
  };

  const SideBarList = (
    <>
      <Link to={DASHBOARD_LINK} data-testid="teradici-logo">
        <div className={classes.drawerHeader}>{teradiciLogo()}</div>
      </Link>
      <List>
        {listItems.map((item) => (
          <Tooltip
            key={item.id}
            title={item.name}
            placement="right"
            disableHoverListener
          >
            <Link to={`${item.link}`} data-testid={`${item.id}-sidebar-link`}>
              <ListItem
                button
                disableRipple
                className={classNames({
                  [classes.currentListItem]: currentPage === item.link,
                })}
                data-testid={`${item.id}-sidebar-item`}
                onClick={handleListItemClick}
              >
                <ListItemIcon className={classes.icon}>
                  <item.Icon />
                </ListItemIcon>
                {open && (
                  <ListItemText
                    primary={item.name}
                    classes={{primary: classes.listItemText}}
                  />
                )}
              </ListItem>
            </Link>
          </Tooltip>
        ))}
      </List>
      <div className={classes.spacer} />
      <div className={classes.toggleButton}>
        <IconButton
          onClick={() => dispatch(toggleSidebar())}
          className={classes.icon}
          data-testid="toggle-sidebar"
          size="large"
        >
          {open ? <CloseIcon /> : <OpenIcon />}
        </IconButton>
      </div>
    </>
  );

  return (
    <Root>
      {/* Full-width */}
      <Hidden mdDown>
        <CustomDrawer
          className={classNames(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          })}
          classes={{
            paper: classNames({
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            }),
          }}
          open={open}
          variant="permanent"
        >
          {SideBarList}
        </CustomDrawer>
      </Hidden>

      {/* Small */}
      <Hidden mdUp>
        <CustomDrawer
          className={classNames(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          })}
          classes={{
            paper: classNames({
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            }),
          }}
          open={open}
          onClose={() => dispatch(closeSidebar())}
          transitionDuration={0}
          variant="temporary"
          PaperProps={{
            elevation: 0,
          }}
        >
          {SideBarList}
        </CustomDrawer>
      </Hidden>
    </Root>
  );
}

export default Sidebar;
