import keyMirror from 'keymirror';
import {PARTNER_USER_STATUS_PENDING} from '../../utils/constants';
import {get, post} from '../../api/Api';

export const userActions = keyMirror({
  FETCH_USERS_START: null,
  FETCH_USERS_SUCCESS: null,
  FETCH_USERS_FAILURE: null,
  FETCH_USER_STATUS_START: null,
  FETCH_USER_STATUS_SUCCESS: null,
  FETCH_USER_STATUS_FAILURE: null,
  FETCH_USER_GROUPS_START: null,
  FETCH_USER_GROUPS_SUCCESS: null,
  FETCH_USER_GROUPS_FAILURE: null,
  FETCH_ACTIVATED_USERS_START: null,
  FETCH_ACTIVATED_USERS_SUCCESS: null,
  FETCH_ACTIVATED_USERS_FAILURE: null,
  FETCH_AUTHENTICATED_USER_START: null,
  FETCH_AUTHENTICATED_USER_SUCCESS: null,
  FETCH_AUTHENTICATED_USER_FAILURE: null,
  UPDATE_USER_STATUS_START: null,
  UPDATE_USER_STATUS_SUCCESS: null,
  UPDATE_USER_STATUS_FAILURE: null,
  UPDATE_USER_ROLE_START: null,
  UPDATE_USER_ROLE_SUCCESS: null,
  UPDATE_USER_ROLE_FAILURE: null,
});

// Fetch Users Actions

export const fetchUsersStart = () => ({
  type: userActions.FETCH_USERS_START,
});

export const fetchUsersSuccess = (users) => ({
  type: userActions.FETCH_USERS_SUCCESS,
  payload: users,
});

export const fetchUsersFailure = (error) => ({
  type: userActions.FETCH_USERS_FAILURE,
  payload: error,
});

// Fetch User Status Actions

export const fetchUserStatusStart = (userId) => ({
  type: userActions.FETCH_USER_STATUS_START,
  payload: {userId},
});

export const fetchUserStatusSuccess = (userId, provisionStatus) => ({
  type: userActions.FETCH_USER_STATUS_SUCCESS,
  payload: {userId, provisionStatus},
});

export const fetchUserStatusFailure = (userId, error) => ({
  type: userActions.FETCH_USER_STATUS_FAILURE,
  payload: {userId, error},
});

// Fetch User Groups Actions

export const fetchUserGroupsStart = (userId) => ({
  type: userActions.FETCH_USER_GROUPS_START,
  payload: {userId},
});

export const fetchUserGroupsSuccess = (userId, groups) => ({
  type: userActions.FETCH_USER_GROUPS_SUCCESS,
  payload: {userId, groups},
});

export const fetchUserGroupsFailure = (userId, error) => ({
  type: userActions.FETCH_USER_GROUPS_FAILURE,
  payload: {userId, error},
});

// Fetch Activated Users Actions

export const fetchActivatedUsersStart = () => ({
  type: userActions.FETCH_ACTIVATED_USERS_START,
});

export const fetchActivatedUsersSuccess = (users) => ({
  type: userActions.FETCH_ACTIVATED_USERS_SUCCESS,
  payload: users,
});

export const fetchActivatedUsersFailure = (error) => ({
  type: userActions.FETCH_ACTIVATED_USERS_FAILURE,
  payload: error,
});

// Fetch Authenticated User Actions

export const fetchAuthenticatedUserStart = () => ({
  type: userActions.FETCH_AUTHENTICATED_USER_START,
});

export const fetchAuthenticatedUserSuccess = (user) => ({
  type: userActions.FETCH_AUTHENTICATED_USER_SUCCESS,
  payload: user,
});

export const fetchAuthenticatedUserFailure = (error) => ({
  type: userActions.FETCH_AUTHENTICATED_USER_FAILURE,
  payload: error,
});

// Update User Status Actions

export const updateUserStatusStart = (userId) => ({
  type: userActions.UPDATE_USER_STATUS_START,
  payload: {userId},
});

export const updateUserStatusSuccess = (userId, provisionStatus) => ({
  type: userActions.UPDATE_USER_STATUS_SUCCESS,
  payload: {userId, provisionStatus},
});

export const updateUserStatusFailure = (userId, error) => ({
  type: userActions.UPDATE_USER_STATUS_FAILURE,
  payload: {userId, error},
});

// Update User Role Actions

export const updateUserRoleStart = (userId) => ({
  type: userActions.UPDATE_USER_ROLE_START,
  payload: {userId},
});

export const updateUserRoleSuccess = (userId, role) => ({
  type: userActions.UPDATE_USER_ROLE_SUCCESS,
  payload: {userId, role},
});

export const updateUserRoleFailure = (userId, error) => ({
  type: userActions.UPDATE_USER_ROLE_FAILURE,
  payload: {userId, error},
});

// Thunk Actions

export const fetchUsers =
  (page, pageSize, searchTerm, orderBy) => async (dispatch) => {
    dispatch(fetchUsersStart());
    try {
      const response = await get({
        path: `users?page=${page}&pageSize=${pageSize}&searchTerm=${searchTerm}&orderBy=${orderBy}`,
      });
      dispatch(fetchUsersSuccess(response.data));
    } catch (error) {
      dispatch(fetchUsersFailure(error.data.message));
    }
  };

export const fetchUserStatus = (userId) => async (dispatch) => {
  dispatch(fetchUserStatusStart(userId));
  try {
    const response = await get({path: `users/${userId}`});
    dispatch(fetchUserStatusSuccess(userId, response.data.provisionStatus));
  } catch (error) {
    dispatch(fetchUserStatusFailure(userId, error.data.message));
  }
};

export const fetchUserGroups = (userId) => async (dispatch) => {
  dispatch(fetchUserGroupsStart(userId));
  try {
    const response = await get({path: `users/${userId}/groups`});
    dispatch(fetchUserGroupsSuccess(userId, response.data));
  } catch (error) {
    dispatch(fetchUserGroupsFailure(userId, error.data.message));
  }
};

export const fetchActivatedUsers = () => async (dispatch) => {
  dispatch(fetchActivatedUsersStart());
  try {
    const response = await get({path: 'users/activated'});
    dispatch(fetchActivatedUsersSuccess(response.data));
  } catch (error) {
    dispatch(fetchActivatedUsersFailure(error.data.message));
  }
};

export const fetchAuthenticatedUser = () => async (dispatch) => {
  dispatch(fetchAuthenticatedUserStart());
  try {
    const response = await get({path: 'me'});
    dispatch(fetchAuthenticatedUserSuccess(response.data));
  } catch (error) {
    dispatch(fetchAuthenticatedUserFailure(error.data.message));
  }
};

export const updateUserStatus = (userId, action) => async (dispatch) => {
  dispatch(updateUserStatusStart(userId));
  const urlMap = {
    activate: `users/${userId}/activate`,
    refresh: `users/${userId}/refresh`,
    deactivate: `users/${userId}/deactivate`,
  };
  try {
    const response = await post({
      path: urlMap[action],
      data: {},
    });
    if (response.code === 200) {
      dispatch(updateUserStatusSuccess(userId, PARTNER_USER_STATUS_PENDING));
    } else {
      throw new Error(response.error || 'Unknown error occurred');
    }
  } catch (error) {
    dispatch(updateUserStatusFailure(userId, error.data.message));
  }
};

export const updateUserRole = (userId, action) => async (dispatch) => {
  dispatch(updateUserRoleStart(userId));
  const urlMap = {
    promote: `users/${userId}/promote`,
    demote: `users/${userId}/demote`,
  };
  try {
    const response = await post({
      path: urlMap[action],
      data: {},
    });
    if (response.code === 200) {
      dispatch(updateUserRoleSuccess(userId, response.data.role));
    } else {
      throw new Error(response.error || 'Unknown error occurred');
    }
  } catch (error) {
    dispatch(updateUserRoleFailure(userId, error.data.message));
  }
};
