import {cloudPCActions} from '../actions/cloudpcActions';

const initialState = {
  cloudPCs: [],
  isLoading: false,
  error: null,
};

// eslint-disable-next-line default-param-last
const cloudPCsReducer = (state = initialState, action) => {
  switch (action.type) {
    case cloudPCActions.FETCH_CLOUDPCS_START:
      return {
        ...state,
        isLoading: true,
      };
    case cloudPCActions.FETCH_CLOUDPCS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        cloudPCs: action.payload,
        error: null,
      };
    case cloudPCActions.FETCH_CLOUDPCS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default cloudPCsReducer;
