import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {enqueueSnackbar} from 'notistack';
import {fetchCloudPCs} from '../../redux/actions/cloudpcActions';
import {fetchTenantStatus} from '../../redux/actions/tenantActions';
import PaginatedTable from '../common/PaginatedTable';
import CloudPCRow from './CloudPCRow';

function CloudPCs() {
  const dispatch = useDispatch();
  const {cloudPCs, isLoading, error} = useSelector((state) => state.cloudPCs);

  useEffect(() => {
    dispatch(fetchTenantStatus());
  }, [dispatch]);

  useEffect(() => {
    if (error) {
      enqueueSnackbar(error, {variant: 'error'});
    }
  }, [error, enqueueSnackbar]);

  const columns = [
    {
      id: 'name',
      name: 'Name',
    },
    {
      id: 'assignedUser',
      name: 'Assigned User',
    },
    {
      id: 'status',
      name: 'Status',
    },
    {
      id: 'anywareAgent',
      name: 'Anyware Agent',
    },
  ];

  return (
    <PaginatedTable
      tableTitle="Cloud PCs"
      isLoading={isLoading}
      data={cloudPCs}
      columns={columns}
      fetchDispatch={fetchCloudPCs}
      dataType="cloudpcs"
      RowObject={CloudPCRow}
    />
  );
}

export default CloudPCs;
