import keyMirror from 'keymirror';
import {get} from '../../api/Api';

export const authActions = keyMirror({
  FETCH_AUTH_STATUS_START: null,
  FETCH_AUTH_STATUS_SUCCESS: null,
  FETCH_AUTH_STATUS_FAILURE: null,
  FETCH_SESSION_SCOPES_START: null,
  FETCH_SESSION_SCOPES_SUCCESS: null,
  FETCH_SESSION_SCOPES_FAILURE: null,
});

// Fetch Auth Status Actions
export const fetchAuthStatusStart = () => ({
  type: authActions.FETCH_AUTH_STATUS_START,
});

export const fetchAuthStatusSuccess = (authStatus) => ({
  type: authActions.FETCH_AUTH_STATUS_SUCCESS,
  payload: authStatus,
});

export const fetchAuthStatusFailure = (error) => ({
  type: authActions.FETCH_AUTH_STATUS_FAILURE,
  payload: error,
});

export const fetchSessionScopesStart = () => ({
  type: authActions.FETCH_SESSION_SCOPES_START,
});

export const fetchSessionScopesSuccess = (scopes) => ({
  type: authActions.FETCH_SESSION_SCOPES_SUCCESS,
  payload: scopes,
});

export const fetchSessionScopesFailure = (error) => ({
  type: authActions.FETCH_SESSION_SCOPES_FAILURE,
  payload: error,
});

// Thunk Actions
export const fetchAuthStatus = () => async (dispatch) => {
  dispatch(fetchAuthStatusStart());
  try {
    // Get uses a base path of /api. Use absolutePath here to override and access the /auth endpoint.
    const response = await get({
      absolutePath: `${window.location.origin}/auth/status`,
    });
    dispatch(fetchAuthStatusSuccess(response.data));
  } catch (error) {
    dispatch(fetchAuthStatusFailure(error.data.message));
  }
};

export const fetchSessionScopes = () => async (dispatch) => {
  dispatch(fetchSessionScopesStart());
  try {
    const response = await get({path: 'scopes/current'});
    dispatch(fetchSessionScopesSuccess(response.data));
  } catch (error) {
    dispatch(fetchSessionScopesFailure(error.data.message));
  }
};
