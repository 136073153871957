import keyMirror from 'keymirror';
import {get, post, put} from '../../api/Api';

export const tenantActions = keyMirror({
  FETCH_TENANT_STATUS_START: null,
  FETCH_TENANT_STATUS_SUCCESS: null,
  FETCH_TENANT_STATUS_FAILURE: null,
  UPDATE_TENANT_STATUS_START: null,
  UPDATE_TENANT_STATUS_SUCCESS: null,
  UPDATE_TENANT_STATUS_FAILURE: null,
  FETCH_TENANT_DEPLOYMENT_START: null,
  FETCH_TENANT_DEPLOYMENT_SUCCESS: null,
  FETCH_TENANT_DEPLOYMENT_FAILURE: null,
  ENABLE_TENANT_START: null,
  ENABLE_TENANT_SUCCESS: null,
  ENABLE_TENANT_FAILURE: null,
  DISABLE_TENANT_START: null,
  DISABLE_TENANT_SUCCESS: null,
  DISABLE_TENANT_FAILURE: null,
  SET_TENANT_TRANSITIONING: null,
});

// Action Creators for fetching tenant status

export const fetchTenantStatusStart = () => ({
  type: tenantActions.FETCH_TENANT_STATUS_START,
});

export const fetchTenantStatusSuccess = (status) => ({
  type: tenantActions.FETCH_TENANT_STATUS_SUCCESS,
  payload: status,
});

export const fetchTenantStatusFailure = (error) => ({
  type: tenantActions.FETCH_TENANT_STATUS_FAILURE,
  payload: error,
});

// Action Creators for updating tenant status

export const updateTenantStatusStart = () => ({
  type: tenantActions.UPDATE_TENANT_STATUS_START,
});

export const updateTenantStatusSuccess = (status) => ({
  type: tenantActions.UPDATE_TENANT_STATUS_SUCCESS,
  payload: status,
});

export const updateTenantStatusFailure = (error) => ({
  type: tenantActions.UPDATE_TENANT_STATUS_FAILURE,
  payload: error,
});

// Action Creators for fetching tenant deployment status

export const fetchTenantDeploymentStart = () => ({
  type: tenantActions.FETCH_TENANT_DEPLOYMENT_START,
});

export const fetchTenantDeploymentSuccess = (
  tenantStatus,
  deploymentStatus
) => ({
  type: tenantActions.FETCH_TENANT_DEPLOYMENT_SUCCESS,
  payload: {tenantStatus, deploymentStatus},
});

export const fetchTenantDeploymentFailure = (error) => ({
  type: tenantActions.FETCH_TENANT_DEPLOYMENT_FAILURE,
  payload: error,
});

// Action Creators for enabling tenant integrations

export const enableTenantStart = () => ({
  type: tenantActions.ENABLE_TENANT_START,
});

export const enableTenantSuccess = () => ({
  type: tenantActions.ENABLE_TENANT_SUCCESS,
});

export const enableTenantFailure = (error) => ({
  type: tenantActions.ENABLE_TENANT_FAILURE,
  payload: error,
});

// Action Creators for disabling tenant integrations

export const disableTenantStart = () => ({
  type: tenantActions.DISABLE_TENANT_START,
});

export const disableTenantSuccess = () => ({
  type: tenantActions.DISABLE_TENANT_SUCCESS,
});

export const disableTenantFailure = (error) => ({
  type: tenantActions.DISABLE_TENANT_FAILURE,
  payload: error,
});

export const setTenantTransitioning = (isActive) => ({
  type: tenantActions.SET_TENANT_TRANSITIONING,
  payload: isActive,
});

// Thunk Actions
export const fetchTenantStatus = () => async (dispatch) => {
  dispatch(fetchTenantStatusStart());
  try {
    const response = await get({path: 'tenant/status'});
    dispatch(fetchTenantStatusSuccess(response.data));
  } catch (error) {
    dispatch(fetchTenantStatusFailure(error.toString()));
  }
};

export const updateTenantStatus = (data) => async (dispatch) => {
  dispatch(updateTenantStatusStart());
  try {
    const response = await put({path: 'tenant/status', data});
    dispatch(updateTenantStatusSuccess(response.data));
  } catch (error) {
    dispatch(updateTenantStatusFailure(error.toString()));
  }
};

export const fetchTenantDeploymentStatus = () => async (dispatch) => {
  dispatch(fetchTenantDeploymentStart());
  try {
    const tenantStatusResponse = await get({path: 'tenant/status'});
    const tenantStatus = tenantStatusResponse.data;
    dispatch(
      setTenantTransitioning(tenantStatus.provisionStatus === 'Transitioning')
    );
    const deploymentStatusResponse = await get({
      path: 'tenant/deploymentStatus',
    });
    dispatch(
      fetchTenantDeploymentSuccess(
        tenantStatusResponse.data,
        deploymentStatusResponse.data
      )
    );
  } catch (error) {
    dispatch(fetchTenantDeploymentFailure(error.toString()));
  }
};

export const enableIntegrations = () => async (dispatch) => {
  dispatch(enableTenantStart());
  dispatch(setTenantTransitioning(true)); // Set transitioning state
  try {
    await post({path: 'tenant/enable'});
    dispatch(enableTenantSuccess());
    // Directly dispatch success without waiting for the status to be 'Enabled'
    // The component will handle polling and updating the UI based on the transitioning state
  } catch (error) {
    dispatch(enableTenantFailure(error.data.message));
  }
};

export const disableIntegrations = () => async (dispatch) => {
  dispatch(disableTenantStart());
  dispatch(setTenantTransitioning(true)); // Set transitioning state
  try {
    await post({path: 'tenant/disable'});
    dispatch(disableTenantSuccess());
    // Directly dispatch success without waiting for the status to be 'Disabled'
    // The component will handle polling and updating the UI based on the transitioning state
  } catch (error) {
    dispatch(disableTenantFailure(error.data.message));
  }
};
