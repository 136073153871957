import {useEffect} from 'react';
import {useSelector} from 'react-redux';
import {
  Alert,
  Box,
  Button,
  IconButton,
  LinearProgress,
  Paper,
  Tooltip,
  Typography,
} from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {enqueueSnackbar} from 'notistack';

const TOOLTIP_TEXT = `HP Anyware for Windows 365 Solution needs a Microsoft Entra ID Global Administrator to grant permissions to access your Azure resources.`;

function AppConsent() {
  const {tenantStatus, isLoading, error} = useSelector(
    (state) => state.tenantStatus
  );

  useEffect(() => {
    if (error) {
      enqueueSnackbar(error, {variant: 'error'});
    }
  }, [error, enqueueSnackbar]);

  const handleConsent = () => {
    window.open('/auth/consent', '_self');
  };

  return (
    <Paper
      sx={{
        padding: '20px',
        display: 'flex',
        flexDirection: 'column',
        gap: '25px',
      }}
    >
      <Typography variant="overline">
        Provide Administrator consent to the HP Anyware for Windows 365 Solution
        <Tooltip title={TOOLTIP_TEXT}>
          <IconButton
            aria-label="More information"
            sx={{
              verticalAlign: 'initial',
              '& .MuiSvgIcon-root': {fontSize: '1rem'},
            }}
          >
            <InfoOutlinedIcon color="primary" />
          </IconButton>
        </Tooltip>
      </Typography>
      {isLoading ? (
        <Box sx={{width: '100%'}}>
          <LinearProgress sx={{marginTop: '50px'}} />
        </Box>
      ) : (
        <>
          <Box sx={{width: '100%'}}>
            <Alert
              variant="outlined"
              severity={tenantStatus?.consentStatus ? 'success' : 'error'}
              sx={{width: '10rem'}}
              data-testid="consent-status"
            >
              {tenantStatus?.consentStatus
                ? 'Consent Granted'
                : 'Needs Consent'}
            </Alert>
          </Box>
          <Box>
            {!tenantStatus?.consentStatus && (
              <Button
                variant="contained"
                disabled={tenantStatus?.consentStatus}
                onClick={handleConsent}
                data-testid="consent-button"
              >
                Consent
              </Button>
            )}
          </Box>
        </>
      )}
    </Paper>
  );
}

export default AppConsent;
