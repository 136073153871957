import {useEffect, useState} from 'react';

function debounce(fn, ms) {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      timer = null;
      fn.apply(this, args);
    }, ms);
  };
}

// calls the callback function when the width of the screen
// crosses some specified breakpoint width
function useBreakpoint(breakpoint, callback, direction = 'both') {
  const [previousWidth, setPreviousWidth] = useState(window.innerHeight);

  useEffect(() => {
    const debouncedHandler = debounce(() => {
      const newWidth = window.innerWidth;
      if (
        (direction !== 'down' &&
          previousWidth < breakpoint &&
          newWidth >= breakpoint) ||
        (direction !== 'up' &&
          previousWidth >= breakpoint &&
          newWidth <= breakpoint)
      ) {
        callback();
      }
      setPreviousWidth(newWidth);
    }, 16);
    window.addEventListener('resize', debouncedHandler);
    return () => window.removeEventListener('resize', debouncedHandler);
  });
}

export default useBreakpoint;
