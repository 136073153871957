import {useEffect} from 'react';
import {useDispatch} from 'react-redux';

import {fetchAuthenticatedUser} from '../redux/actions/userActions';
import {fetchSessionScopes} from '../redux/actions/authActions';

const useLocalUser = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAuthenticatedUser());
    dispatch(fetchSessionScopes());
  }, [dispatch]);
};

export default useLocalUser;
