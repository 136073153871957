import {announcementActions} from '../actions/announcementActions';

const initialState = {
  announcements: [],
  stateCounter: 0,
  isLoading: false,
  error: null,
};

// eslint-disable-next-line default-param-last
const announcementReducer = (state = initialState, action) => {
  switch (action.type) {
    case announcementActions.FETCH_ANNOUNCEMENT_START:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case announcementActions.FETCH_ANNOUNCEMENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        announcements: action.payload,
      };
    case announcementActions.FETCH_ANNOUNCEMENT_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        stateCounter: state.stateCounter + 1,
      };
    default:
      return state;
  }
};

export default announcementReducer;
