import {
  AWM_EULA_LINK,
  PRIVACY_STATEMENT_LINK,
  UNAPPROVED_TENANT_REDIRECT_LINK,
  UNAPPROVED_TENANT_REDIRECT_SECONDS,
} from './utils/constants';

const config = {
  AWM_EULA_LINK: import.meta.env.VITE_AWM_EULA_LINK || AWM_EULA_LINK,
  PRIVACY_STATEMENT_LINK:
    import.meta.env.VITE_PRIVACY_STATEMENT_LINK || PRIVACY_STATEMENT_LINK,
  UNAPPROVED_TENANT_REDIRECT_LINK:
    import.meta.env.VITE_UNAPPROVED_TENANT_REDIRECT_LINK ||
    UNAPPROVED_TENANT_REDIRECT_LINK,
  UNAPPROVED_TENANT_REDIRECT_SECONDS:
    import.meta.env.VITE_UNAPPROVED_TENANT_REDIRECT_SECONDS ||
    UNAPPROVED_TENANT_REDIRECT_SECONDS,
};

export default config;
