import {post} from '../api/Api';

export function delay(ms) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

export function isEmpty(obj = {}) {
  return (
    [Object, Array].includes((obj || {}).constructor) &&
    !Object.entries(obj || {}).length
  );
}

export function isUndefined(value) {
  return typeof value === 'undefined';
}

export const handleLogout = async () => {
  await post({absolutePath: `${window.location.origin}/auth/logout`})
  localStorage.clear();
  window.location.href = '/';
};
