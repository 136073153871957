import {combineReducers} from '@reduxjs/toolkit';
import approvedTenantReducer from './approvedTenantReducer';
import authReducer from './authReducer';
import cloudpcReducer from './cloudpcReducer';
import licensesReducer from './licensesReducer';
import routerReducer from './HistoryReducer';
import sidebarReducer from './sidebarReducer';
import tenantStatusReducer from './tenantReducer';
import usersReducer from './usersReducer';
import groupsReducer from './groupReducer';
import announcementReducer from './announcementReducer';

const rootReducer = combineReducers({
  approvedTenant: approvedTenantReducer,
  authStatus: authReducer,
  cloudPCs: cloudpcReducer,
  licenses: licensesReducer,
  router: routerReducer,
  sidebar: sidebarReducer,
  tenantStatus: tenantStatusReducer,
  users: usersReducer,
  groups: groupsReducer,
  announcements: announcementReducer,
});

export default rootReducer;
