import queryString from 'query-string';
import Cookies from 'js-cookie';
import {BACKEND_ERROR_MESSAGE} from '../utils/constants';
import {delay, isUndefined} from '../utils/utils';

const gatewayAddress = window.location.origin;
const baseUrl = `${gatewayAddress}/api/`;

/**
 * FUTURE:
 * - auto retry (in progress)
 * - auto save to redux store
 * - comprehensive debugging capabilities
 */

const getEndpoint = (path) => `${baseUrl}${path}`;

const getHeaders = () => {
  const csrfToken = Cookies.get('XSRF-TOKEN');
  return {
    'Content-Type': 'application/json',
    'cache-control': 'no-cache',
    'CSRF-Token': csrfToken,
  };
};

// This is the only functions that needs modification in case we need
// to change the library used to do HTTP requests
const doRequest = async ({
  method,
  path,
  absolutePath,
  params,
  data,
  headers,
  isDownload,
  signal,
}) => {
  let url;
  if (absolutePath) {
    url = absolutePath;
  } else {
    url = getEndpoint(path);
  }

  if (params) {
    url += `?${queryString.stringify(params)}`;
  }

  try {
    const response = await window.fetch(url, {
      method,
      credentials: 'include',
      headers: headers || getHeaders(),
      body: JSON.stringify(data),
      signal,
    });

    if (response.status === 401) {
      window.location.href = '/auth/login';
      return Promise.reject(new Error('Authentication required.'));
    }

    let responseData;
    if (isDownload) {
      responseData = await response.blob();
    } else if (response.status === 502 || response.status === 504) {
      throw new Error({
        code: response.status,
        message: BACKEND_ERROR_MESSAGE,
      });
    } else {
      responseData = await response.json();
    }

    // Uncomment to simulate API return errors for testing the retry logic
    // if (Math.random() > 0.7) {
    //   throw ({ code: Math.random() < 0.5 ? 429 : 500 });
    // }

    if (response.ok) {
      return responseData;
    }
    throw responseData;
  } catch (error) {
    throw error;
  }
};

// Retry Logic for API calls

// https://www.restapitutorial.com/httpstatuscodes.html
const HTTP_CODES_TO_RETRY = [500, 502, 503, 410, 429];

const waitBeforeRetry = async (attempt, maxRetries, errorCode) => {
  // Do not wait before retrying 500 errors as they are not due to scarce resources.
  if ([500].includes(errorCode)) {
    return;
  }
  if (maxRetries <= 0) {
    return;
  }
  const MIN_WAIT_TIME_BEFORE_RETRY = 1000;
  const MAX_WAIT_TIME_BEFORE_RETRY = 5000;

  const delayDuration = Math.trunc(
    MIN_WAIT_TIME_BEFORE_RETRY +
      Math.random() * attempt * (MAX_WAIT_TIME_BEFORE_RETRY / maxRetries)
  );
  await delay(delayDuration);
};

const MAX_RETRIES = 3;

const doRequestWithRetry = async (requestInfo) => {
  const maxRetries = isUndefined(requestInfo.maxRetries)
    ? MAX_RETRIES
    : requestInfo.maxRetries;
  let errorCode;
  let retryCount = 0;
  do {
    try {
      // eslint-disable-next-line no-await-in-loop
      return await doRequest(requestInfo);
    } catch (error) {
      errorCode = error.code;
      if (!HTTP_CODES_TO_RETRY.includes(errorCode)) {
        throw error;
      }

      if (retryCount === maxRetries) {
        throw error;
      }
      retryCount += 1;
      // eslint-disable-next-line no-await-in-loop
      await waitBeforeRetry(retryCount, maxRetries, errorCode);
    }
  } while (retryCount <= maxRetries);
  return false;
};

export const get = async (requestInfo) =>
  doRequestWithRetry({method: 'GET', ...requestInfo});
export const del = async (requestInfo) =>
  doRequestWithRetry({method: 'DELETE', ...requestInfo});
export const post = async (requestInfo) =>
  doRequestWithRetry({method: 'POST', ...requestInfo});
export const put = async (requestInfo) =>
  doRequestWithRetry({method: 'PUT', ...requestInfo});

/**
 * Complete requests in batches for a long array of query params and return aggregated results
 * @param   {Object} batchRequestOptions              Options object for batching requests
 * @param   {Object} batchRequestOptions.queryParams  Query params object with all query params for the requests
 * @param   {String} batchRequestOptions.batchingKey  Query param key on which the request needs to be batched
 * @param   {Number} batchRequestOptions.batchSize    Number of query params in each batched request
 * @param   {String} batchRequestOptions.path         URL path for the batched requests

 * @returns {Object} aggregatedResult
 * @returns {String} aggregatedResult.status

 * @returns {Object} aggregatedResult.info
 * @returns {Number} aggregatedResult.info.successfulRequestCount
 * @returns {Number} aggregatedResult.info.failedRequestCount
 * @returns {Number} aggregatedResult.info.totalRequestCount
 * @returns {Number} aggregatedResult.info.totalResourceCount
 * @returns {Number} aggregatedResult.info.batchSize

 * @returns {Array}  aggregatedResult.data
 */
export const batchRequest = async ({
  queryParams,
  batchingKey,
  batchSize = 50,
  path,
}) => {
  // Perform sanity check on input params
  if (isUndefined(path)) {
    throw new Error('no path provided to batch requests for');
  }

  if (isUndefined(queryParams)) {
    throw new Error('no queryParams provided to batch requests for');
  }

  if (isUndefined(batchingKey)) {
    throw new Error('no batchingKey provided to batch requests for');
  }

  if (isUndefined(queryParams[batchingKey])) {
    throw new Error(`invalid batchingKey "${batchingKey}" provided`);
  }

  if (!Array.isArray(queryParams[batchingKey])) {
    throw new Error('cannot batch requests for non array query param');
  }

  // Break queryParams[batchingKey] into chunks of provided batch size
  const batches = [];
  const totalParams = queryParams[batchingKey].length;
  for (let i = 0; i < totalParams; i += batchSize) {
    batches.push(queryParams[batchingKey].slice(i, i + batchSize));
  }

  // Iterate over chunks making requests
  const responses = await Promise.all(
    batches.map(async (batch) => {
      const requestParams = {
        path,
        params: {
          ...queryParams,
          [batchingKey]: batch.join(','),
          limit: batchSize,
        },
      };

      let response;
      try {
        response = await get(requestParams);
      } catch (err) {
        response = err;
      }
      return response;
    })
  );

  // Aggregate results
  const success = responses.filter((resp) => resp.status === 'success');
  const failed = responses.filter((resp) => resp.status !== 'success');
  const data = success.reduce((prev, curr) => [...prev, ...curr.data], []);
  return {
    info: {
      successfulRequestCount: success.length,
      failedRequestCount: failed.length,
      totalRequestCount: responses.length,
      totalResourceCount: success.reduce(
        (prev, curr) => prev + curr.data.length,
        0
      ),
      batchSize,
    },
    data,
    status: success.length === responses.length ? 'success' : 'fail',
  };
};
