import {useSelector} from 'react-redux';
import {styled} from '@mui/material/styles';
import {AppBar, Toolbar} from '@mui/material/';
import classNames from 'classnames';
import Hidden from '@mui/material/Hidden';
import UserMenu from './UserMenu';
import {handleLogout} from '../../../utils/utils';

const PREFIX = 'TopBar';

const classes = {
  appBar: `${PREFIX}-appBar`,
  toolbar: `${PREFIX}-toolbar`,
  toolbarExpanded: `${PREFIX}-toolbarExpanded`,
  toolbarCollapsed: `${PREFIX}-toolbarCollapsed`,
  search: `${PREFIX}-search`,
  searchIcon: `${PREFIX}-searchIcon`,
  searchInputRoot: `${PREFIX}-searchInputRoot`,
  searchInputText: `${PREFIX}-searchInputText`,
  grow: `${PREFIX}-grow`,
  tenantIdLabel: `${PREFIX}-deploymentFilterButton`,
};

const StyledAppBar = styled(AppBar)(({theme}) => ({
  [`&.${classes.appBar}`]: {
    backgroundColor: '#fafafa',
    borderBottom: '1px solid rgba(0,0,0,0.12)',
    boxShadow: 'none',
  },

  [`& .${classes.toolbar}`]: {
    height: '80px',
    transition: theme.transitions.create(['width'], {
      delay: 150,
      easing: theme.transitions.easing.ease,
      duration: 200,
    }),
  },

  [`& .${classes.toolbarExpanded}`]: {
    height: '80px',
    [theme.breakpoints.up('md')]: {
      paddingLeft: '240px',
      transition: theme.transitions.create(['padding-left'], {
        delay: 150,
        easing: theme.transitions.easing.ease,
        duration: 200,
      }),
    },
  },

  [`& .${classes.toolbarCollapsed}`]: {
    height: '80px',
    [theme.breakpoints.up('md')]: {
      paddingLeft: '55px',
      transition: theme.transitions.create(['padding-left'], {
        delay: 150,
        easing: theme.transitions.easing.ease,
        duration: 200,
      }),
    },
  },

  [`& .${classes.search}`]: {
    position: 'relative',
    border: '1px solid rgba(0,0,0,0.12)',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: '#fafafa',
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '360px',
  },

  [`& .${classes.searchIcon}`]: {
    width: theme.spacing(9),
    height: '100%',
    position: 'absolute',
    color: theme.palette.primary.main,
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  [`& .${classes.searchInputRoot}`]: {
    color: theme.palette.primary.main,
    width: '100%',
    height: '48px',
  },

  [`& .${classes.searchInputText}`]: {
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(10),
    width: '100%',
    height: '100%',
  },

  [`& .${classes.grow}`]: {flexGrow: 1},

  [`& .${classes.tenantIdLabel}`]: {
    marginLeft: '40px',
    textTransform: 'none',
    color: theme.palette.primary.main,
    fontSize: '0.9rem',
    fontWeight: 500,
    letterSpacing: '1.25px',
  },
}));

function TopBar() {
  const {authStatus} = useSelector((state) => state.authStatus);
  const upn = authStatus?.user?.username;
  const tenantId = authStatus?.user?.tenantId;

  return (
    <StyledAppBar className={classes.appBar}>
      <Toolbar className={classNames(classes.toolbar, classes.toolbarExpanded)}>
        <Hidden mdDown>
          <div className={classes.tenantIdLabel}>
            {`Tenant ID: ${tenantId}`}
          </div>
        </Hidden>
        <div className={classes.grow} />
        <UserMenu handleSignout={handleLogout} userName={upn} />
      </Toolbar>
    </StyledAppBar>
  );
}

export default TopBar;
