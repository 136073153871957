import {
  Box,
  Chip,
  IconButton,
  ListItem,
  ListItemSecondaryAction,
  Radio,
} from '@mui/material';
import VpnKeyIconOutlined from '@mui/icons-material/VpnKeyOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import PropTypes from 'prop-types';
import {Can} from '../common/Can';

const maskLicense = (license) => license.replace(/[a-zA-Z0-9](?=.{4})/g, '*');

function LicenseItem({license, onActivate, onDelete}) {
  return (
    <ListItem key={license._id} disableGutters>
      {license.active && (
        <Box
          sx={{
            position: 'absolute',
            left: 0,
            transform: 'translateX(-75%) rotate(-180deg)',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '20px',
            writingMode: 'vertical-rl',
            textOrientation: 'mixed',
            fontSize: '1rem',
            color: 'primary.main',
          }}
        >
          Active
        </Box>
      )}
      <Can I="update" a="license" passThrough>
        {(canUpdateLicense) => (
          <Radio
            disabled={!canUpdateLicense}
            checked={license.active}
            onChange={() => onActivate(license.licenseKey)}
          />
        )}
      </Can>
      <Chip
        icon={<VpnKeyIconOutlined />}
        label={maskLicense(license.licenseKey)}
        color={license.active ? 'primary' : 'default'}
        variant="outlined"
        sx={{fontFamily: 'monospace', paddingRight: '24px'}}
      />
      <Can I="delete" a="license">
        {!license.active && (
          <ListItemSecondaryAction>
            <IconButton
              aria-label="delete"
              onClick={() => onDelete(license.licenseKey)}
            >
              <DeleteIcon />
            </IconButton>
          </ListItemSecondaryAction>
        )}
      </Can>
    </ListItem>
  );
}
LicenseItem.propTypes = {
  license: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    licenseKey: PropTypes.string.isRequired,
    active: PropTypes.bool.isRequired,
  }).isRequired,
  onActivate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default LicenseItem;
