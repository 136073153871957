import keyMirror from 'keymirror';
import {get} from '../../api/Api';

export const approvedTenantActions = keyMirror({
  FETCH_TENANT_APPROVAL_STATUS_START: null,
  FETCH_TENANT_APPROVAL_STATUS_SUCCESS: null,
  FETCH_TENANT_APPROVAL_STATUS_FAILURE: null,
});

// Fetch Tenant Approval Status Actions

export const fetchTenantApprovalStatusStart = () => ({
  type: approvedTenantActions.FETCH_TENANT_APPROVAL_STATUS_START,
});

export const fetchTenantApprovalStatusSuccess = (status) => ({
  type: approvedTenantActions.FETCH_TENANT_APPROVAL_STATUS_SUCCESS,
  payload: status,
});

export const fetchTenantApprovalStatusFailure = (error) => ({
  type: approvedTenantActions.FETCH_TENANT_APPROVAL_STATUS_FAILURE,
  payload: error,
});

// Thunk Actions

export const fetchTenantApprovalStatus = () => async (dispatch) => {
  dispatch(fetchTenantApprovalStatusStart());
  let response;
  try {
    response = await get({path: 'tenant/approval'});
    dispatch(fetchTenantApprovalStatusSuccess(response.data.approved));
  } catch (error) {
    dispatch(fetchTenantApprovalStatusFailure(error.data.message));
  }
};
