import ButtonBase from '@mui/material/ButtonBase';
import {styled} from '@mui/material/styles';
import classnames from 'classnames';
import PropTypes from 'prop-types';

const PREFIX = 'KebabMenuItem';

const classes = {
  disabled: `${PREFIX}-disabled`,
  item: `${PREFIX}-item`,
  textWrapper: `${PREFIX}-textWrapper`,
  textWrapperWithIcon: `${PREFIX}-textWrapperWithIcon`,
  menuWidth: `${PREFIX}-menuWidth`,
};

const StyledListItem = styled(ButtonBase)(({width}) => ({
  [`& .${classes.disabled}`]: {
    opacity: 0.4,
  },

  [`&.${classes.item}`]: {
    width: '10.3em',
    paddingTop: 6,
    paddingBottom: 6,
    paddingLeft: 8,
    paddingRight: 8,
    fontSize: 14,
    color: 'black',
  },

  [`& .${classes.textWrapper}`]: {
    width: '100%',
  },

  [`& .${classes.textWrapperWithIcon}`]: {
    width: '80%',
  },

  [`&.${classes.menuWidth}`]: {
    width: `${width}px`,
  },
}));

function KebabMenuItem({
  dataTestId,
  enableClick,
  icon,
  menuText,
  onClick,
  width,
}) {
  const onClickEnabled = enableClick ? onClick : () => false;

  return (
    <StyledListItem
      onClick={onClickEnabled}
      className={classnames(classes.item, classes.menuWidth)}
      classes={{disabled: classes.disabled}}
      disabled={!enableClick}
      button
      width={width}
      data-testid={dataTestId}
    >
      <div className={icon ? classes.textWrapperWithIcon : classes.textWrapper}>
        {menuText}
      </div>
      {icon}
    </StyledListItem>
  );
}

KebabMenuItem.propTypes = {
  dataTestId: PropTypes.string,
  enableClick: PropTypes.bool,
  icon: PropTypes.node,
  menuText: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
    .isRequired,
  onClick: PropTypes.func,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

KebabMenuItem.defaultProps = {
  dataTestId: '',
  enableClick: true,
  icon: null,
  onClick: () => {},
  width: null,
};

export default KebabMenuItem;
