import {useEffect} from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';
import {useDispatch, useSelector} from 'react-redux';
import {fetchUserGroups} from '../../redux/actions/userActions';

function UserGroupDialog({open, onClose, userId, userName}) {
  const dispatch = useDispatch();
  const {isLoading, userGroups} = useSelector((state) => state.users);
  const groups = userGroups[userId] || {};

  useEffect(() => {
    if (open) {
      dispatch(fetchUserGroups(userId));
    }
  }, [open, dispatch, userId]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>{userName}&apos;s Groups</DialogTitle>
      <DialogContent>
        {isLoading ? (
          <CircularProgress />
        ) : (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Group Name</TableCell>
                  <TableCell>Group ID</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.keys(groups).length > 0 ? (
                  Object.entries(groups).map(([key, group]) => (
                    <TableRow key={key}>
                      <TableCell>{group.groupName}</TableCell>
                      <TableCell>{group.groupId}</TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={2}>No groups found</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}

UserGroupDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired,
  userName: PropTypes.string.isRequired,
};

export default UserGroupDialog;
