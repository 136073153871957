import {useState, useEffect} from 'react';
import {styled} from '@mui/material/styles';
import {Box, Toolbar, IconButton} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import WarningIcon from '@mui/icons-material/Warning';
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const PREFIX = 'Banner';

const classes = {
  panel: `${PREFIX}-panel`,
  summary: `${PREFIX}-summary`,
  message: `${PREFIX}-message`,
  flexDiv: `${PREFIX}-flexDiv`,
  icon: `${PREFIX}-icon`,
  closeIcon: `${PREFIX}-closeIcon`,
};

const StyledBox = styled(Box)(({theme}) => ({
  [`&.${classes.panel}`]: {
    position: 'relative',
    borderRadius: '4px',
    width: '100%',
    height: 'fit-content',
  },
  [`& .${classes.summary}`]: {
    minHeight: '40px !important',
    borderRadius: '10px',
    marginTop: '5px',
    padding: '0px',
    paddingLeft: '24px',
    paddingRight: '16px',
  },
  [`& .${classes.message}`]: {
    fontSize: '14px',
    left: 0,
    letterSpacing: 0,
    lineHeight: '18.2px',
  },
  [`& .${classes.flexDiv}`]: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '8px',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  [`& .${classes.icon}`]: {
    position: 'relative',
    padding: '0px',
    width: '20px',
    height: '20px',
  },
  [`& .${classes.closeIcon}`]: {
    marginLeft: 'auto',
  },
}));

function Banner({announcements}) {
  const [visibleAnnouncements, setVisibleAnnouncements] = useState([]);

  useEffect(() => {
    const storedVisibility = JSON.parse(sessionStorage.getItem('visibleAnnouncements')) || [];
    const initialVisibility = announcements.map((_, index) => storedVisibility[index] ?? true);
    setVisibleAnnouncements(initialVisibility);
  }, [announcements]);

  useEffect(() => {
    sessionStorage.setItem('visibleAnnouncements', JSON.stringify(visibleAnnouncements));
  }, [visibleAnnouncements]);

  const handleClose = (index) => {
    setVisibleAnnouncements((prev) => {
      const newVisible = [...prev];
      newVisible[index] = false;
      return newVisible;
    });
  };

  const getVariantColors = (severity) => {
    switch (severity) {
      case 'error':
        return {
          mainColor: '#833226',
          borderColor: '#F38C7E',
          lightenedColor: '#FDEAE8',
        };
      case 'warning':
        return {
          mainColor: '#844D0A',
          borderColor: '#F5AF59',
          lightenedColor: '#FEF3E7',
        };
      case 'info':
        return {
          mainColor: '#1B4A8C',
          borderColor: '#72ADFF',
          lightenedColor: '#E5F0FF',
        };
      case 'success':
        return {
          mainColor: '#176947',
          borderColor: '#6AD1A7',
          lightenedColor: '#EAFAF4',
        };
      default:
        return {
          mainColor: '#23242A',
          borderColor: '#C7C9D1',
          lightenedColor: '#F8F8FB',
        };
    }
  };

  const getSeverityIcon = (severity) => {
    switch (severity) {
      case 'error':
        return <ErrorIcon />;
      case 'warning':
        return <WarningIcon />;
      case 'success':
        return <CheckCircleIcon />;
      default:
        return <InfoIcon />;
    }
  };

  return (
    <div>
      {announcements.map(({message, severity}, index) => {
        const {mainColor, borderColor, lightenedColor} =
          getVariantColors(severity);

        return (
          visibleAnnouncements[index] && (
            <StyledBox className={classes.panel} key={index}>
              <Toolbar
                className={classes.summary}
                style={{
                  backgroundColor: lightenedColor,
                  color: mainColor,
                  border: `${borderColor} 1px solid`,
                }}
              >
                {getSeverityIcon(severity)}
                <div className={classes.flexDiv}>
                  <div className={classes.message}>{message}</div>
                </div>
                <IconButton
                  onClick={() => handleClose(index)}
                  className={classNames(classes.icon, classes.closeIcon)}
                  size="large"
                >
                  <CloseIcon />
                </IconButton>
              </Toolbar>
            </StyledBox>
          )
        );
      })}
    </div>
  );
}

Banner.propTypes = {
  announcements: PropTypes.arrayOf(
    PropTypes.shape({
      message: PropTypes.string.isRequired,
      severity: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default Banner;
