import keyMirror from 'keymirror';
import {get} from '../../api/Api';

export const announcementActions = keyMirror({
  FETCH_ANNOUNCEMENT_START: null,
  FETCH_ANNOUNCEMENT_SUCCESS: null,
  FETCH_ANNOUNCEMENT_FAILURE: null,
});

// Fetch Announcement Actions

export const fetchAnnouncementStart = () => ({
  type: announcementActions.FETCH_ANNOUNCEMENT_START,
});

export const fetchAnnouncementSuccess = (announcement) => ({
  type: announcementActions.FETCH_ANNOUNCEMENT_SUCCESS,
  payload: announcement,
});

export const fetchAnnouncementFailure = (error) => ({
  type: announcementActions.FETCH_ANNOUNCEMENT_FAILURE,
  payload: error,
});

export const fetchAnnouncements = () => async (dispatch) => {
  dispatch(fetchAnnouncementStart());
  try {
    const response = await get({path: 'announcements'});
    dispatch(fetchAnnouncementSuccess(response.data.announcements));
  } catch (error) {
    dispatch(fetchAnnouncementFailure(error.data.message));
  }
};
